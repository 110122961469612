import Modal from 'react-bootstrap/Modal';
import translations from '../../../data/footprint/lang.json';
import {useContext} from 'react';
import {Settings} from '../../../store';
import {gsap} from 'gsap';

const CheckboxConfirmationModal = (props) => {
    const {
        isCheckboxModalOpen, hideCheckboxModal, setCheckboxAlert, swiper, summaryPage, setInvalidValidation,
    } = props;
    const settingsState = useContext(Settings.State);
    const dispatch = useContext(Settings.Dispatch);
    const {language} = settingsState;
    const handleClick = () => {
        hideCheckboxModal();
        // if (checkboxAlert) {
        //     setCheckboxAlert();
        // }
    };

    return (<Modal size="sm"
                   aria-labelledby="contained-modal-title-vcenter"
                   centered show={isCheckboxModalOpen} onHide={hideCheckboxModal}
    >
        <div className="modal-header">
            <button onClick={hideCheckboxModal} type="button" className="btn-close" data-bs-dismiss="modal"
                    aria-label="Close"></button>
        </div>
        <div className="modal-body p-6">
            <h4 className="mb-3 text-center">{translations[language].confirmationModal}</h4>
            <p className="text-center mb-3 mb-lg-5">{translations[language].confirmationModalText}</p>
            <div className="d-flex flex-wrap gap-lg-3 gap-2 justify-content-center">
                <button style={{display: summaryPage && 'none'}} className="btn btn-link" onClick={() => {
                    handleClick();
                    dispatch({
                        type: 'setNegativeImpact',
                        payload: 'A. ' + translations[language].investments_content.conclusion.title1,
                    });
                    dispatch({type: 'setAlertTextNegativeImpact', payload: ''});
                    // const element = document.getElementById('negativeImpactBlock');
                    // element.classList.remove('negative-impact-alert')
                    swiper.slideTo(swiper.activeIndex + 1);
                }}>{translations[language].confirmationModalYes}
                </button>
                <button className="btn btn-primary" onClick={() => {
                    handleClick();
                    dispatch({type: 'setCheckboxItemsSkip', payload: true});
                    if (summaryPage) {
                        setTimeout(()=> {
                            const element = document.getElementById('negativeImpactBlock');
                            element.scrollIntoView({ behavior: 'smooth'});
                            setInvalidValidation(true)
                        },500)
                    }
                }}>{translations[language].confirmationModalNo}
                </button>
            </div>
        </div>
    </Modal>);
};

export default CheckboxConfirmationModal;