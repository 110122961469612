import React from 'react';
import sectorsRemData from '../../../data/impact/sectorsremaining-data.json';
import sectorCodes from '../../../data/impact/sector-codes.json'

const RemainingSectorsList = () => {

    return (
            <ul className="m-0 p-0 list-group list-group-flush">
                <hr className="my-4"/>
                <h4 className="mb-2">Sectors</h4>
                {Object.keys(sectorsRemData).map((item) => {
                    const sectorName = sectorsRemData[item].name;
                    return <li className="list-group-item ps-0">{sectorCodes[sectorName].name}</li>;
                })
                }
            </ul>
    );
};

export default RemainingSectorsList;
