import React, {useEffect, useRef, useState} from 'react';
import {gsap} from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import WorldMapSVG from './WorldMapSVG';
import {SpreadGrid} from '../../../hooks/spreadGrid';
import ContinentCard from './ContinentCard';
import continentsData from '../../../data/impact/continents-data.json';
import ContinentModal from './ContinentModal';

const Cards = (props) => {
    gsap.registerPlugin(ScrollTrigger);
    const {negativeCalculation} = props;
    const [activeContinent, setActiveContinent] = useState(continentsData['continent1']);
    const [totalImpactValue, setTotalImpactValue] = useState(0);
    const el = useRef(null);
    const elGroup = gsap.utils.selector(el);
    const [isOpen, setIsOpen] = useState(false);

    const showModal = () => {
        if (!isOpen) {
            setIsOpen(true);
        }
    };

    const hideModal = () => {
        document.getElementById('continent_cards').querySelector('.card[data-id="' + activeContinent.id + '"]').click();
        setIsOpen(false);
    };

    useEffect(() => {
        elGroup('.grid').forEach((item) => {
            gsap.set(item, {
                height: item.clientHeight,
            });
        });
        gsap.set(elGroup('.grid__item'), {
            scale: 0,
            rotation: 0,
            x: 0,
            y: 0,
            opacity: 0,
        });
        let tl = gsap.timeline({
            scrollTrigger: {
                trigger: el.current,
                start: 'top 50%',
                end: 'bottom',
                scrub: false,
            },
        });
        let delay = 0.05;
        let sum = 0;

        Object.keys(continentsData).forEach((item, index) => {
            sum += continentsData[item].negativeImpactAverageFiveYears;
            tl.to(elGroup('.grid__item')[index], {
                opacity: 1,
                scale: 1,
                delay: delay,
                duration: 0.5,
            }, 'same');

            delay += 0.05;
        });
        setTotalImpactValue(sum);

        if (window.innerWidth > 992) {
            [...elGroup('.grid')].forEach(grid => new SpreadGrid(grid));
        }

        gsap.set(elGroup('.word span'), {
            y: 100,
            position: 'relative',
            opacity: 0,
            transitionTimingFunction: 'cubic-bezier(0.19, 1, 0.22, 1)',
            display: 'inline-block',
        });
        delay = 0.05;
        gsap.utils.toArray(elGroup('.word span')).forEach(function(e, index) {
            gsap.to(elGroup('.word span')[index], {
                scrollTrigger: {
                    trigger: el.current,
                    start: 'top 70%',
                    scrub: false,
                },
                y: 0,
                ease: 'expo.out',
                duration: 0.8,
                delay,
                opacity: 1,
            });
            delay += 0.05;
        });
    }, []);

    return (
            <section id={'continent_cards'} ref={el} className="block block--cards"
                     data-spy={'#continent_cards'}
                     data-section-theme="blue">
                <div className="block__content">
                <div className="container d-flex flex-column gap-5 gap-lg-6">
                    <div className="row justify-content-center text-center mt-lg-5">
                        <div className="col-auto block__heading">
                          <h2 className="formue-animation large">Continents</h2>
                          <p className="lead formue-animation" data-delay={0.1}>This is how your share of the negative impact is distributed across the continents.</p>
                        </div>
                      </div>
                        <div className="row justify-content-center">
                            <div className="col-lg-9">
                              <div className="formue-animation text-center mb-5" data-delay={0.4}>
                              <div className="dynamic-tooltip">
                                <div className="dynamic-tooltip__inner">
                                  <p>Click them for more details</p>
                                </div>
                              </div>
                             </div>
                                <div className="position-relative">
                                    <div className="grid d-block d-lg-grid" style={{
                                        display: 'grid',
                                        gridTemplateColumns: 'repeat(10, 1fr)',
                                        gridTemplateRows: 'repeat(10, 1fr)',
                                        gap: 24,
                                    }}
                                         data-duration="1"
                                         data-ease="elastic.out(0.5)"
                                         data-scale="1.3"
                                         data-max-rotation="20"
                                         data-spread="150"
                                         data-max-distance="700"
                                    >
                                        {Object.keys(continentsData).map((item, index) => {
                                            let percent = 100 * continentsData[item].negativeImpactAverageFiveYears / totalImpactValue;

                                            if (percent >= 1) {
                                                percent = percent.toFixed(0);
                                            } else {
                                                percent = percent.toFixed(3);
                                            }

                                            const image = continentsData[item].img;
                                            const id = continentsData[item].id;
                                            const grid = continentsData[item].grid;
                                            const name = continentsData[item].name;


                                            return <ContinentCard
                                                    img={image}
                                                    percent={percent}
                                                    setActiveContinent={setActiveContinent}
                                                    cardID={id}
                                                    grid={grid}
                                                    showModal={showModal}
                                                    key={id}
                                                    name={name}
                                                    negativeCalculation={negativeCalculation}
                                            />;
                                        })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="world-map">
                        <WorldMapSVG/>
                    </div>
                </div>
                <ContinentModal
                        activeContinent={activeContinent}
                        isOpen={isOpen}
                        hideModal={hideModal}
                        totalImpactValue={totalImpactValue}
                        negativeCalculation={negativeCalculation}
                />
            </section>
    );
};

export default Cards;
