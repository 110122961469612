import React, {useRef, useEffect, useState} from 'react';
import {gsap} from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import sectorsData from '../../../data/impact/sectors-data.json';
import continentsData from '../../../data/impact/continents-data.json';
import sdgData from '../../../data/impact/sdg-data.json';
import {Settings} from '../../../store';
import Form from 'react-bootstrap/Form';
import SectorsColumn from './SectorsColumn';
import ContinentsColumn from './ContinentsColumn';
import SDGColumn from './SDGColumn';
import translations from '../../../data/impact/lang.json';
import {langSeparator} from '../../../hooks/helperFunctions';

const TakeAction = (props) => {
    gsap.registerPlugin(ScrollTrigger);

    const {negativeCalculation, investmentValue} = props;
    const take_action_title = useRef(null);
    const arrowSection = useRef(null);
    const whatToDo = useRef(null);

    const elGroup = gsap.utils.selector(take_action_title);
    const [totalImpactValue, setTotalImpactValue] = useState(0);
    const [totalSDGImpactValue, setTotalSDGImpactValue] = useState(0);
    const [link, setLink] = useState('https://footprint.formue.no/');
    const settingsState = React.useContext(Settings.State);
    const [activeButton, setActiveButton] = useState(false);
    const {language} = settingsState;
    useEffect(() => {

    }, [activeButton]);


    useEffect(() => {
        switch (language) {
            case 'no':
                setLink('https://footprint.formue.no/');
                break;
            case 'se':
                setLink('https://footprint.formue.se/');
                break;
            case 'com':
                setLink('https://footprint.formue.com/');
                break;
            default:
                setLink('https://footprint.formue.no/');

        }
    }, [language]);

    useEffect(() => {

        gsap.set(elGroup('.heading-animation'), {
            x: window.innerWidth,
        });

        let sum = 0;
        Object.keys(sectorsData).forEach((key, index) => {
            sum += sectorsData[key].value;
        });
        setTotalImpactValue(sum);

        sum = 0;
        Object.keys(sdgData).forEach((item, index) => {
            sum += sdgData[item].totalSectorImpact;
        });
        setTotalSDGImpactValue(sum);

    }, []);

    useEffect(() => {


        gsap.set('#animated-arrow', {
            x: 50,
            y: -50,
        });

        setTimeout(() => {
            let tl = gsap.timeline({
                scrollTrigger: {
                    trigger: take_action_title.current,
                    start: 'top 60%',
                    end: 'bottom',
                    scrub: true,
                    invalidateOnRefresh: true,
                },
            });
            tl.to(elGroup('.heading-animation'), {
                x: -window.innerWidth,
            });

            let tl2 = gsap.timeline({
                scrollTrigger: {
                    trigger: arrowSection.current,
                    start: 'top 40%',
                    end: 'bottom 60%',
                    scrub: true,
                    invalidateOnRefresh: true,
                },
            });
            tl2.to('#animated-arrow', {
                x: 0,
                y: 0,
            });
        }, 500);


        // gsap.set('.engage-bars rect,#engage-bubble,#cake,#cake-bubble, #text-w-1,#text-w-2,#text-w-3,#small-text-1,#small-text-2,#small-text-3,#small-text-4,#sdgs-animation img', {
        //     opacity: 0,
        // });

        // gsap.set('#sdgs-animation img', {
        //     x: -20,
        // });
        // gsap.set('#text-w-1,#text-w-2,#text-w-3,#engage-bubble,.engage-bars rect', {
        //     y: 20,
        // });


        gsap.set('.engage-bars rect', {
            transition: 'opacity 0.2s ease',
        });

        gsap.set('#cake-bubble', {
            x: 30,
            y: -10,
        });

        setTimeout(() => {
            let tl = gsap.timeline({
                scrollTrigger: {
                    trigger: whatToDo.current,
                    start: 'top 30%',
                    scrub: false,
                    invalidateOnRefresh: true,
                },
                defaults: {duration: 0.2},
            });
            tl.to('#cake', {
                opacity: 1,
            }).to('#move-cake-part', {
                x: 20,
            });

            // .to('#cake-bubble', {
            //     y: -30,
            //     opacity: 1,
            // })

            //
            //
            // .to('#text-w-1', {
            //     opacity: 1,
            //     y: 0,
            //     duration: 0.1,
            // });
            //
            // tl.to('#small-text-1', {
            //     opacity: 1,
            //     y: 0,
            //
            // }, 'same1');
            // let delay = 0;
            // gsap.utils.toArray('#engage-bars-1 rect').forEach(function(e) {
            //
            //     tl.to(e, {
            //         opacity: 1,
            //         y: 0,
            //         delay: delay,
            //     }, 'same1');
            //     delay += 0.1;
            // });
            //
            //
            // tl.to('#small-text-2', {
            //     opacity: 1,
            //     y: 0,
            // }, 'same1');
            // delay = 0;
            // gsap.utils.toArray('#engage-bars-2 rect').forEach(function(e) {
            //
            //     tl.to(e, {
            //         opacity: 1,
            //         y: 0,
            //         delay: delay,
            //     }, 'same1');
            //     delay += 0.1;
            // });
            //
            //
            // tl.to('#small-text-3', {
            //     opacity: 1,
            //     y: 0,
            // }, 'same1');
            // delay = 0;
            // gsap.utils.toArray('#engage-bars-3 rect').forEach(function(e) {
            //
            //     tl.to(e, {
            //         opacity: 1,
            //         y: 0,
            //         delay: delay,
            //     }, 'same1');
            //     delay += 0.1;
            // });
            //
            //
            // tl.to('#small-text-4', {
            //     opacity: 1,
            //     y: 0,
            // }, 'same1');
            // delay = 0;
            // gsap.utils.toArray('#engage-bars-4 rect').forEach(function(e) {
            //
            //     tl.to(e, {
            //         opacity: 1,
            //         y: 0,
            //         delay: delay,
            //     }, 'same1');
            //     delay += 0.1;
            // });
            //
            // tl.to('#engage-bubble', {
            //     opacity: 1,
            //     y: 0,
            // }).to('#text-w-2', {
            //     opacity: 1,
            //     y: 0,
            //     duration: 0.1,
            // });
            //
            // gsap.utils.toArray('#sdgs-animation img').forEach(function(e) {
            //     tl.to(e, {
            //         opacity: 1,
            //         x: 0,
            //         delay: delay,
            //     }, 'same2');
            //     delay += 0.1;
            // });
            // tl.to('#text-w-3', {
            //     opacity: 1,
            //     y: 0,
            //     duration: 0.1,
            // });


        }, 500);


    }, []);

    return (
            <section id="take-action" className="takeAction block">
                <section id="take_action_title" ref={take_action_title}
                         className="block block--invert block--rolling-header block--takeaction block--sticky green">
                    <div className="overflow-hidden">
                        <div className="block__content h-auto d-flex align-items-center">
                            <div className="container">
                                <div className="row d-flex justify-content-center text-center">
                                    <div className="col-lg-12">
                                        <h2 className="block__heading block__heading--large">
                                            <span>Take action. </span><span>Take action. </span><span>Take action. </span><span>Take action. </span><span>Take action. </span><span>Take action. </span>
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section
                        className="block block--rounded-top block--takeaction" data-section-theme="default">
                    <div className="block__content pb-3 pb-lg-6">
                        <div className="container d-flex flex-column gap-5 gap-lg-6">
                            <div className="row justify-content-center text-center mt-lg-5">

                                <div className="col-auto d-flex flex-column block__heading gap-2 gap-lg-3 align-items-center"
                                     ref={arrowSection}>
                                    <svg width="200" height="200" viewBox="0 0 400 400" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path id="animated-arrow"
                                              d="M297.5 142.5H274.68C273.567 142.5 273.007 141.152 273.795 140.365L299.195 114.967C300.17 113.993 300.172 112.407 299.195 111.432L288.59 100.822C287.615 99.845 286.03 99.845 285.055 100.822L259.635 126.242C258.847 127.03 257.5 126.473 257.5 125.36V102.5C257.5 101.12 256.38 100 255 100H240C238.62 100 237.5 101.12 237.5 102.5V147.333C237.5 147.995 237.237 148.632 236.767 149.1L230.377 155.49L230.372 155.485L188.722 197.113C187.745 198.088 187.745 199.672 188.722 200.647L199.325 211.257C200.3 212.235 201.885 212.235 202.86 211.257L241.155 172.983L241.16 172.987L250.92 163.23C251.39 162.762 252.025 162.5 252.687 162.5H297.5C298.88 162.5 300 161.38 300 160V145C300 143.62 298.88 142.5 297.5 142.5Z"
                                              fill="#724362"/>
                                        <path d="M195.335 272.352C159.267 270.082 129.917 240.732 127.647 204.665C125.05 163.415 157.175 128.885 197.557 127.54C198.912 127.495 200 126.417 200 125.06V110.052C200 108.652 198.845 107.497 197.445 107.535C145.382 108.947 104.06 153.375 107.727 206.56C110.872 252.172 147.827 289.127 193.44 292.272C246.627 295.94 291.055 254.615 292.467 202.555C292.502 201.155 291.35 200 289.947 200H274.94C273.582 200 272.505 201.087 272.46 202.445C271.115 242.825 236.587 274.95 195.335 272.352Z"
                                              fill="#A0496D"/>
                                        <path d="M195.997 234.778C179.985 232.99 167.007 220.015 165.222 204.003C162.957 183.703 178.185 166.335 197.725 165.075C199.02 164.99 200 163.865 200 162.57V147.573C200 146.163 198.832 144.995 197.425 145.058C167.097 146.455 143.18 172.418 145.11 203.508C146.81 230.898 169.102 253.19 196.492 254.89C227.582 256.82 253.545 232.903 254.942 202.575C255.007 201.168 253.837 200 252.427 200H237.43C236.135 200 235.01 200.98 234.927 202.273C233.667 221.815 216.3 237.04 195.997 234.778Z"
                                              fill="#F2637B"/>
                                    </svg>
                                    <h2 className="formue-animation medium" data-delay={0.2}>What have you<br/>learned?
                                    </h2>
                                    <p className="lead formue-animation" data-delay={0.4}>You have just explored
                                        the negative impacts of
                                        investing <strong>{investmentValue.toLocaleString(langSeparator(language), {maximumFractionDigits: 0})} {translations[language].general.currency}</strong>.
                                        Here is a short summary of what you have learned:</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="block__content pt-0">
                        <div className="container">
                            <div className="row g-6 g-xl-9">
                                <SectorsColumn data={sectorsData} totalImpactValue={totalImpactValue}
                                               negativeCalculation={negativeCalculation}/>
                                <ContinentsColumn data={continentsData} totalImpactValue={totalImpactValue}
                                                  negativeCalculation={negativeCalculation}/>
                                <SDGColumn data={sdgData} totalSDGImpactValue={totalSDGImpactValue}
                                           negativeCalculation={negativeCalculation}/>
                            </div>
                        </div>
                    </div>
                    <div ref={whatToDo} className="block__content pt-5">
                        <div className="container d-flex flex-column gap-5 gap-lg-6">
                            <div className="row justify-content-center text-center mt-lg-5">
                                <div className="col-auto d-flex flex-column gap-2 gap-lg-3 block__heading">
                                    <h2 className="mb-3 formue-animation">What should you do with<br/> your investments?
                                    </h2>
                                    <p className="lead formue-animation" data-delay={0.1}>EU has made it mandatory for
                                        wealth managers to assess clients’ sustainability preferences. Have you assessed
                                        yours yet? Here is our simple approach:</p>
                                </div>
                            </div>
                            <div className="row g-8 text-center justify-content-center text-lg-start">
                                <div className="col-sm-8 col-lg-4">
                                    <div className="card h-100">
                                        <div className="card-media">
                                            <div className="card-illustration position-relative d-inline-block formue-animation">
                                                <svg id="cake" width="200" height="200" viewBox="0 0 200 200"
                                                     fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M145.033 153.033C134.544 163.522 121.18 170.665 106.632 173.559C92.0832 176.453 77.0032 174.968 63.2987 169.291C49.5943 163.614 37.8809 154.001 29.6398 141.668C21.3987 129.334 17 114.834 17 100C17 85.1664 21.3987 70.666 29.6398 58.3323C37.8809 45.9986 49.5943 36.3856 63.2987 30.7091C77.0032 25.0325 92.0832 23.5472 106.632 26.4411C121.18 29.335 134.544 36.4781 145.033 46.967L92 100L145.033 153.033Z"
                                                          fill="#173621"/>
                                                    <path id="move-cake-part"
                                                          d="M145.033 47C151.997 53.9644 157.522 62.2323 161.291 71.3318C165.06 80.4312 167 90.1839 167 100.033C167 109.882 165.06 119.635 161.291 128.734C157.522 137.834 151.997 146.102 145.033 153.066L92 100.033L145.033 47Z"
                                                          fill="#ABD4A3"/>
                                                </svg>

                                                <div id="cake-bubble"
                                                     className="dynamic-tooltip dynamic-tooltip--left position-absolute start-50 bottom-50 formue-animation"
                                                     data-delay={0.1}>
                                                    <div className="dynamic-tooltip__inner text-center"><p>Share in
                                                        sustainable
                                                        solutions</p>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body">

                                            <div className="formue-animation" data-delay={0.2}>
                                                <h5 className="card-title">1. Share in sustainable solutions</h5>
                                                <p className="card-text">You can choose to have a dedicated share of
                                                    your
                                                    investments in sustainable
                                                    solutions.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-8 col-lg-4">
                                    <div className="card h-100">
                                        <div className="card-media">
                                            <div className={activeButton ? 'active position-relative pt-4' : 'position-relative pt-4'}>
                                                <div
                                                     className="dynamic-tooltip position-absolute start-50 bottom-100 translate__middle--x formue-animation"
                                                     data-delay={0.7}>
                                                    <div className="dynamic-tooltip__inner">
                                                        <div className="d-flex gap-2 justify-content-center align-items-center my-1">
                                                            <p>Engage</p>
                                                            <Form className="ps-2" onChange={() => {
                                                                setActiveButton(prevState => !prevState);
                                                            }}>
                                                                <Form.Check
                                                                        type="switch"
                                                                        id="switch"
                                                                />
                                                            </Form>
                                                            <p>Exclude</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="d-flex g-2 row">
                                                    <div className="col-3 text-center formue-animation"
                                                         data-delay={0.3}>
                                                        <svg className="engage-bars" width="32"
                                                             height="44"
                                                             viewBox="0 0 32 44" fill="none"
                                                             xmlns="http://www.w3.org/2000/svg">
                                                            <rect y="36" width="32" height="8" rx="2"
                                                                  fill="#437047"/>
                                                        </svg>

                                                        <p className="small lh-sm mt-1"><strong className="small">Sustainable</strong></p>
                                                    </div>
                                                    <div className="col-3 text-center formue-animation"
                                                         data-delay={0.4}>
                                                        <svg className="engage-bars" width="32"
                                                             height="44"
                                                             viewBox="0 0 32 44" fill="none"
                                                             xmlns="http://www.w3.org/2000/svg">
                                                            <rect y="36" width="32" height="8" rx="2"
                                                                  fill="#385795"/>
                                                            <rect y="24" width="32" height="8" rx="2"
                                                                  fill="#385795"/>
                                                            <rect y="12" width="32" height="8" rx="2"
                                                                  fill="#385795"/>
                                                            <rect className={activeButton && 'opacity-0'} width="32"
                                                                  height="8" rx="2" fill="#385795"/>
                                                        </svg>
                                                        <p className="small lh-sm mt-1"><strong className="small">Neutral</strong></p>
                                                    </div>
                                                    <div className="col-3 text-center formue-animation"
                                                         data-delay={0.5}>
                                                        <svg className="engage-bars" width="32"
                                                             height="44"
                                                             viewBox="0 0 32 44" fill="none"
                                                             xmlns="http://www.w3.org/2000/svg">
                                                            <rect className={activeButton && 'opacity-0'} y="36"
                                                                  width="32"
                                                                  height="8" rx="2" fill="#F692A3"/>
                                                            <rect className={activeButton && 'opacity-0'} y="24"
                                                                  width="32"
                                                                  height="8" rx="2" fill="#F692A3"/>
                                                        </svg>
                                                        <p className="small lh-sm mt-1"><strong className="small">Transition</strong></p>
                                                    </div>
                                                    <div className="col-3 text-center formue-animation"
                                                         data-delay={0.6}>
                                                        <svg className="engage-bars" width="32"
                                                             height="44"
                                                             viewBox="0 0 32 44" fill="none"
                                                             xmlns="http://www.w3.org/2000/svg">
                                                            <rect className={activeButton && 'opacity-0'} y="36"
                                                                  width="32"
                                                                  height="8" rx="2" fill="#96355D"/>
                                                        </svg>

                                                        <p className="small lh-sm mt-1"><strong className="small">Significant Harm</strong></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="formue-animation" data-delay={0.8}>
                                                <h5 className="card-title">2. Engage or Exclude</h5>
                                                <p className="card-text">You can follow our engagement process with fund
                                                    managers to push improvement or to exclude the companies that do not
                                                    meet the criterias.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-8 col-lg-4">
                                    <div className="card h-100">
                                        <div className="card-media">
                                            <div className="d-flex gap-3 formue-animation pb-4"
                                                 data-delay={0.9}>
                                                <img className="sdg-item" style={{maxWidth: 56}} src={'../media/SDGs/6.svg'} alt="sdg"/>
                                                <img className="sdg-item" style={{maxWidth: 56}} src={'../media/SDGs/13.svg'} alt="sdg"/>
                                                <img className="sdg-item" style={{maxWidth: 56}} src={'../media/SDGs/15.svg'} alt="sdg"/>
                                                <img className="sdg-item" style={{maxWidth: 56}} src={'../media/SDGs/14.svg'} alt="sdg"/>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="formue-animation" data-delay={1}>
                                                <h5 className="card-title">3. Holistic approach or specific themes</h5>
                                                <p className="card-text">You can follow our holistic approach for
                                                    reducing
                                                    negative impacts or choose specific themes I care about.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    {/*<div className="block__content s2">
                        <div className="container text-center">
                            <div className="row justify-content-center">
                                <div className="col-lg-8 formue-animation">
                                    <h2 className="mb-4">Explore your wealth footprint through our tool</h2>
                                    <a className="btn btn-primary" href={link}>Wealth Footprint Tool</a>
                                </div>
                            </div>
                        </div>
                    </div>*/}
                </section>
                <section className="block">
                    <div className="block__content s2">
                        <div className="container d-flex flex-column gap-5 gap-lg-6">
                            <div className="row justify-content-center text-center mt-lg-5">
                                <div className="col-auto d-flex flex-column gap-2 gap-lg-3 block__heading">
                                    <h2 className="formue-animation">What more can you do?</h2>
                                    <p className="lead formue-animation" data-delay={0.1}>Your wealth footprint is
                                        driven by your investments, your lifestyle, and the business you may own.
                                        Knowing the drivers allows you to make the adjustments that are right for
                                        you. Explore your wealth footprint through our tool</p>
                                        <div className="formue-animation" data-delay={0.2}>
                                        <a className="btn btn-primary d-block d-sm-inline" href={link}>Wealth Footprint Tool</a>
                                        </div>
                                </div>
                            </div>
                            {/*<div className="row g-4 g-lg-9">
                                <div className="col-lg-4 formue-animation">
                                    <div className="position-relative">
                                        <div className="position-absolute d-flex align-items-center p-4 justify-content-between mt-5 ms-6 bg-white">
                                        <span>
                                            <svg width="32" height="32" viewBox="0 0 32 32" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path d="M13.6004 30.947H11.2004C10.9796 30.947 10.8004 30.7678 10.8004 30.547V18.4002C10.8004 18.1882 10.6332 18.0154 10.4216 18.0034C7.73882 17.8506 5.29921 17.381 3.14841 16.6022C2.74922 16.4574 2.35802 16.3014 1.98002 16.1358C1.77562 16.0462 1.68762 15.8034 1.78202 15.601L2.79322 13.4246C2.88522 13.2266 3.11762 13.1414 3.31802 13.2282C3.61562 13.357 3.92361 13.4794 4.23842 13.5934C6.55642 14.433 9.30281 14.8586 12.4008 14.8586H13.6008C13.8216 14.8586 14.0008 15.0378 14.0008 15.2586V30.547C14.0004 30.7678 13.8212 30.947 13.6004 30.947Z"
                                                      fill="#A0496D"/>
                                                <path d="M20.8005 30.9107H18.4005C18.1797 30.9107 18.0005 30.7315 18.0005 30.5107V22.4227C18.0005 22.2019 18.1797 22.0227 18.4005 22.0227H19.6005C22.6981 22.0227 25.4441 21.5971 27.7625 20.7575C28.0769 20.6435 28.3849 20.5215 28.6833 20.3923C28.8841 20.3055 29.1165 20.3903 29.2085 20.5887L30.2185 22.7659C30.3125 22.9683 30.2245 23.2111 30.0197 23.3007C29.6409 23.4663 29.2497 23.6223 28.8517 23.7663C26.7009 24.5451 24.2613 25.0143 21.5789 25.1671C21.3673 25.1791 21.2001 25.3519 21.2001 25.5639V30.5107C21.2005 30.7315 21.0213 30.9107 20.8005 30.9107Z"
                                                      fill="#A0496D"/>
                                                <path d="M22.8444 19.3338C19.7564 19.3338 17.2444 16.8218 17.2444 13.7338C17.2444 10.6458 19.7564 8.13379 22.8444 8.13379C25.9324 8.13379 28.4444 10.6458 28.4444 13.7338C28.4444 16.8218 25.9324 19.3338 22.8444 19.3338ZM22.8444 11.3338C21.5208 11.3338 20.4444 12.4102 20.4444 13.7338C20.4444 15.0574 21.5208 16.1338 22.8444 16.1338C24.168 16.1338 25.2444 15.0574 25.2444 13.7338C25.2444 12.4102 24.168 11.3338 22.8444 11.3338Z"
                                                      fill="#F2637B"/>
                                                <path d="M9.1564 12.3338C6.0684 12.3338 3.5564 9.82179 3.5564 6.73379C3.5564 3.64579 6.0684 1.13379 9.1564 1.13379C12.2444 1.13379 14.7564 3.64579 14.7564 6.73379C14.7564 9.82179 12.244 12.3338 9.1564 12.3338ZM9.1564 4.33379C7.8328 4.33379 6.7564 5.41019 6.7564 6.73379C6.7564 8.05739 7.8328 9.13379 9.1564 9.13379C10.48 9.13379 11.5564 8.05739 11.5564 6.73379C11.5564 5.41019 10.4796 4.33379 9.1564 4.33379Z"
                                                      fill="#724362"/>
                                            </svg>
                                        </span>
                                            <p className="font-weight-medium m-0 ms-3">Lifestyle</p>
                                        </div>
                                        <img className="w-100" src={'../media/ta1.png'} alt="ta1"/>
                                    </div>
                                </div>
                                <div className="col-lg-4 formue-animation" data-delay={0.1}>
                                    <div className="position-relative">
                                        <div className="position-absolute bottom-0 d-flex align-items-center p-4 justify-content-between mb-6 bg-white">
                                        <span>
                                           <svg width="32" height="32" viewBox="0 0 32 32" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
<path d="M2.4265 18.9903L0.773703 17.2499C0.621703 17.0899 0.628103 16.8367 0.788503 16.6843L13.2857 4.59592C13.4429 4.44672 13.6905 4.44992 13.8441 4.60312L19.1233 9.88232C19.2781 10.0371 19.5289 10.0387 19.6857 9.88552L23.1961 6.48832C23.3541 6.33392 23.6073 6.33672 23.7617 6.49512L25.4385 8.21192C25.5929 8.36992 25.5897 8.62312 25.4317 8.77752L19.6597 14.3851C19.5029 14.5383 19.2521 14.5367 19.0973 14.3819L13.7861 9.07072C13.6329 8.91752 13.3853 8.91432 13.2277 9.06352L2.9921 19.0051C2.8317 19.1571 2.5785 19.1507 2.4265 18.9903Z"
      fill="#464F81"/>
<path d="M30.4 12H28C27.7792 12 27.6 11.8208 27.6 11.6V4.79995C27.6 4.57915 27.4208 4.39995 27.2 4.39995H20.4C20.1792 4.39995 20 4.22075 20 3.99995V1.59995C20 1.37915 20.1792 1.19995 20.4 1.19995H30.4C30.6208 1.19995 30.8 1.37915 30.8 1.59995V11.6C30.8 11.8208 30.6208 12 30.4 12Z"
      fill="#45A2FF"/>
<path d="M26.8 29.5999H24.4C24.1792 29.5999 24 29.4207 24 29.1999V16.7999C24 16.5791 24.1792 16.3999 24.4 16.3999H26.8C27.0208 16.3999 27.2 16.5791 27.2 16.7999V29.1999C27.2 29.4207 27.0208 29.5999 26.8 29.5999Z"
      fill="#4C68A0"/>
<path d="M20.4001 29.6H18.0001C17.7793 29.6 17.6001 29.4208 17.6001 29.2V19.6C17.6001 19.3792 17.7793 19.2 18.0001 19.2H20.4001C20.6209 19.2 20.8001 19.3792 20.8001 19.6V29.2C20.8001 29.4208 20.6209 29.6 20.4001 29.6Z"
      fill="#4C68A0"/>
<path d="M14 29.6H11.6C11.3792 29.6 11.2 29.4208 11.2 29.2V16.4C11.2 16.1792 11.3792 16 11.6 16H14C14.2208 16 14.4 16.1792 14.4 16.4V29.2C14.4 29.4208 14.2208 29.6 14 29.6Z"
      fill="#4C68A0"/>
<path d="M7.60005 29.6001H5.20005C4.97925 29.6001 4.80005 29.4209 4.80005 29.2001V22.0001C4.80005 21.7793 4.97925 21.6001 5.20005 21.6001H7.60005C7.82085 21.6001 8.00005 21.7793 8.00005 22.0001V29.2001C8.00005 29.4209 7.82085 29.6001 7.60005 29.6001Z"
      fill="#4C68A0"/>
</svg>

                                        </span>
                                            <p className="font-weight-medium m-0 ms-3">Investments</p>
                                        </div>
                                        <img className="w-100" src={'../media/ta1.png'} alt="ta1"/>
                                    </div>
                                </div>
                                <div className="col-lg-4 formue-animation" data-delay={0.2}>
                                    <div className="position-relative">
                                        <div className="position-absolute bottom-0 d-flex align-items-center p-4 justify-content-between mb-9 bg-white">
                                        <span>
                                         <svg width="32" height="32" viewBox="0 0 32 32" fill="none"
                                              xmlns="http://www.w3.org/2000/svg">
<path d="M30.8 22.8H28.4C28.1792 22.8 28 22.6208 28 22.4V15.6C28 15.3792 27.8208 15.2 27.6 15.2H20.4C20.1792 15.2 20 15.0208 20 14.8V12.4C20 12.1792 20.1792 12 20.4 12H30.8C31.0208 12 31.2 12.1792 31.2 12.4V22.4C31.2 22.6208 31.0208 22.8 30.8 22.8Z"
      fill="#C76FF2"/>
<path d="M13.2456 31.1165L11.572 29.3961C11.418 29.2377 11.4216 28.9845 11.5796 28.8305L23.386 17.3585C23.5444 17.2045 23.7976 17.2077 23.9516 17.3661L25.6256 19.0865C25.7796 19.2449 25.776 19.4981 25.618 19.6521L13.8112 31.1241C13.6532 31.2781 13.3996 31.2749 13.2456 31.1165Z"
      fill="#C76FF2"/>
<path d="M5.1999 31.1603H2.7999C2.5791 31.1603 2.3999 30.9811 2.3999 30.7603V5.81351C2.3999 5.62831 2.5267 5.46751 2.7067 5.42431L23.5067 0.450311C23.7583 0.390311 23.9999 0.581111 23.9999 0.839511V9.70991C23.9999 9.93071 23.8207 10.1099 23.5999 10.1099H21.1999C20.9791 10.1099 20.7999 9.93071 20.7999 9.70991V4.64151C20.7999 4.51231 20.6791 4.41711 20.5535 4.44711L5.9071 7.94951C5.7267 7.99271 5.5999 8.15351 5.5999 8.33871V30.7607C5.5999 30.9815 5.4207 31.1603 5.1999 31.1603Z"
      fill="#7C599F"/>
<path d="M11.6 12H9.20005C8.97925 12 8.80005 12.1792 8.80005 12.4V14.8C8.80005 15.0208 8.97925 15.2 9.20005 15.2H11.6C11.8208 15.2 12 15.0208 12 14.8V12.4C12 12.1792 11.8208 12 11.6 12Z"
      fill="#59497A"/>
<path d="M11.6 17.2H9.20005C8.97925 17.2 8.80005 17.3792 8.80005 17.6V20C8.80005 20.2208 8.97925 20.4 9.20005 20.4H11.6C11.8208 20.4 12 20.2208 12 20V17.6C12 17.3792 11.8208 17.2 11.6 17.2Z"
      fill="#59497A"/>
<path d="M11.6 22.3999H9.20005C8.97925 22.3999 8.80005 22.5791 8.80005 22.7999V25.1999C8.80005 25.4207 8.97925 25.5999 9.20005 25.5999H11.6C11.8208 25.5999 12 25.4207 12 25.1999V22.7999C12 22.5791 11.8208 22.3999 11.6 22.3999Z"
      fill="#59497A"/>
</svg>
                                        </span>
                                            <p className="font-weight-medium m-0 ms-3">Business</p>
                                        </div>
                                        <img className="w-100" src={'../media/ta1.png'} alt="ta1"/>
                                    </div>
                                </div>
                            </div>*/}
                        </div>
                    </div>
                </section>
            </section>
    );
};

export default TakeAction;
