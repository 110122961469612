import React from 'react';
import Modal from 'react-bootstrap/Modal';
import companyData from '../../../data/impact/company-data.json';

const CompanyModal = (props) => {
    const {
        isOpenCompanyModal, hideCompanyModal, activeData,
    } = props;

    const logo = companyData[activeData].logo;
    const map = companyData[activeData].map;
    const type = companyData[activeData].type;
    const data = companyData[activeData].data;
    return (<Modal size="sm" dialogClassName={'modal-fullscreen-md-down'}
                   backdrop={true}
                   aria-labelledby="contained-modal-title-vcenter"
                   centered show={isOpenCompanyModal} onHide={() => {
        hideCompanyModal();
    }}
    >
        <div className="overlay-background"></div>
        <div className="modal-header">
            <button onClick={hideCompanyModal} type="button" className="btn-close" data-bs-dismiss="modal"
                    aria-label="Close"></button>
        </div>
        <div className="modal-body d-flex bg-transparent justify-content-center align-items-center p-8">
            <div className="text-center">
                <img style={{width: 'auto'}} src={'../media/' + logo} alt={logo}/>
                <img style={{width: 'auto'}} src={'../media/' + map} alt={map}/>

                <div className="text-center">
                    {type === 'outcome' ? <div className="btn btn-primary">Outcome</div> :
                            <div className="btn btn-outline-primary">Impacts</div>}
                </div>
                <div>
                    {data.map((item) => {
                        let name = item.name;
                        let value = item.value;
                        let lastRow = '';
                        if(name === 'Total Cost/impacts') {
                            lastRow = 'bold';
                        }
                        return <div className={"d-flex align-items-center justify-content-between mb-2 " + lastRow }>
                            <span>{name}</span><strong>{value}</strong></div>;
                    })}
                </div>
            </div>
        </div>
    </Modal>);
};

export default CompanyModal;
