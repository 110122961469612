import React, {useRef, useEffect, useState} from 'react';
import {gsap} from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import {langSeparator, useScrollPosition} from '../../../hooks/helperFunctions';
import {Settings} from '../../../store';
import translations from '../../../data/impact/lang.json';
import {ProgressBar} from 'react-bootstrap';

const Impact = (props) => {
    const {negativeCalculation, isMainActive, negativeImpactAverageFiveYears} = props;
    gsap.registerPlugin(ScrollTrigger);
    const block = useRef(null);
    const el = useRef(null);
    const settingsState = React.useContext(Settings.State);
    const {language} = settingsState;
    const [bottomAnimation, setBottomAnimation] = useState(40);
    const [xAnimation, setXAnimation] = useState(50);
    const [width, setWidth] = useState(window.innerWidth);
    const [progressBar, setProgressBar] = useState(0);
    const progressBarRef = useRef(null);


    // useEffect(() => {
    //     const handleScroll = () => {
    //         const elementPos = myRef.current.offsetTop + myRef.current.offsetHeight;
    //         const viewportHeight = window.innerHeight + window.pageYOffset;
    //
    //         setIsTop(elementPos <= window.pageYOffset);
    //     };
    //
    //     window.addEventListener("scroll", handleScroll);
    //
    //     return () => {
    //         window.removeEventListener("scroll", handleScroll);
    //     };
    // }, [myRef]);
    //
    //
    // useEffect(() => {
    //    console.log(isTop)
    // }, [isTop]);


    useEffect(() => {
        const handleResize = () => setWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };


    }, []);

    useEffect(() => {
        if (width < 768) {
            setBottomAnimation(20);
            setXAnimation(20);
        }
    }, [width]);


    useEffect(() => {


        if (progressBar === 100 || progressBar === 0) {
            progressBarRef.current.classList.add('opacity-0');
        } else {
            progressBarRef.current.classList.remove('opacity-0');
        }

    }, [progressBar]);


    useEffect(() => {
        gsap.set('#logos', {
            y: 100,
            opacity: 0,
        });
        gsap.set('#boxes', {
            x: 300,
            opacity: 0,
        });
        gsap.set('#nestle', {
            y: 20,
            opacity: 0,
        });
        gsap.set('#cocacola', {
            y: 20,
            opacity: 0,
        });
        gsap.set('#companies', {
            y: 20,
            opacity: 0,
        });
        gsap.set('#tooltip-company', {
            y: -32,
            right: 0,
            opacity: 0,
        });
        gsap.set('#score', {
            top: -80,
            right: 0,
            opacity: 0,
        });
        gsap.set('#rounded-text', {
            transform: ' translate(0, 0)',
            opacity: 0,
        });


        setTimeout(() => {
            const tl = gsap.timeline({
                scrollTrigger: {
                    trigger: block.current,
                    start: 'top 60%',
                    end: 'bottom 120%',
                    scrub: 0.2,
                    invalidateOnRefresh: true,
                    onUpdate: (self) => {
                        setProgressBar(self.progress * 100);
                    },
                },
                onComplete: () => {
                    document.getElementById('section-header').classList.add('active');

                },

            });

            tl.to('#logos', {
                y: 0,
                opacity: 1,
            });
            tl.to('#rounded-text', {
                transform: ' translate(-8px, -8px)',
                opacity: 1,
            }, 'samea');
            tl.to('#nestle', {
                y: 0,
                opacity: 1,
            }, 'samea');
            tl.to('#cocacola', {
                y: '0%',
                opacity: 1,
            });
            tl.to('#companies', {
                y: 0,
                opacity: 1,
            });
            tl.to('#tooltip-company', {
                y: -48,
                opacity: 1,

            });

            tl.to('#logos', {
                x: -300,
                opacity: 0,

            }, 'same');
            tl.to('#boxes', {
                x: 0,
                opacity: 1,
                delay: 0.3,
            }, 'same');
            tl.to(el.current, {
                rotate: 17,
                x: xAnimation,
                bottom: bottomAnimation,
            });
            tl.to('#score', {
                top: -96,
                opacity: 1,
                onReverseComplete: () => {
                    document.getElementById('section-header').classList.remove('active');

                },

            });
        }, 500);

    }, [isMainActive]);
    let text = '<textPath startOffset="57%" fill="#000" alignment-baseline="top" xlink:href="#curve">MSCI World Index</textPath>';
    return (
            <section className="block block--msci " ref={block} data-section-theme="red" style={{height: '300vh'}}>

                <div className="top-0 position-sticky">
                    <div className="block__content d-flex align-items-center vh-100 overflow-hidden position-relative">
                        <ProgressBar ref={progressBarRef}
                                     className="top-0 position-absolute w-100"
                                     style={{transition: 'opacity 0.2s ease'}} now={progressBar}/>
                        <div className="container">

                            <div className="row justify-content-center align-items-center">
                                <div className="col-lg-6 text-center formue-animation">
                                    <div className="position-relative m-auto" style={{maxWidth: 450}}>
                                        <div id="logos" className="position-relative">
                                            <svg className="msci-base mw-100 h-100" width="511" height="400"
                                                 viewBox="0 0 511 400" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <text id="rounded-text" width="200"
                                                      dangerouslySetInnerHTML={{__html: text}}></text>
                                                <path id="curve"
                                                      d="M510.638 187.866C510.638 359.355 412.03 400 240.532 400C69.035 400 0 359.355 0 187.866C0 16.3772 82.2261 0 253.723 0C425.221 0 510.638 16.3772 510.638 187.866Z"
                                                      fill="#FAD6DB"/>
                                            </svg>
                                            <div className="py-5 px-6 p-sm-7 position-absolute start-0 end-0 top-0 bottom-0 d-flex">
                                                <div className="d-flex justify-content-center flex-column row company-logos">
                                                    <div className="col d-flex align-items-center justify-content-start">
                                                        <img id="nestle" className="company-logos__item"
                                                             style={{maxWidth: 136}}
                                                             src={'../media/nestle.png'} alt="companies"/>
                                                    </div>
                                                    <div className="col d-flex align-items-center justify-content-end">
                                                        <img id="cocacola" className="company-logos__item"
                                                             style={{maxWidth: 136}}
                                                             src={'../media/cocacola.png'} alt="companies"/>
                                                    </div>
                                                    <div className="col d-flex align-items-center">
                                                        <p id="companies" className="lead"><strong>+ 1534
                                                            Companies</strong></p>
                                                    </div>
                                                    <div id="tooltip-company"
                                                         className="position-absolute top-0 d-flex justify-content-end">
                                                        <div className="dynamic-tooltip dynamic-tooltip--left">
                                                            <div className="dynamic-tooltip__inner"><p>Total negative impact:</p>
                                                                <h6>- {negativeImpactAverageFiveYears}</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="position-absolute top-0 start-50 translate__middle--x">
                                            <div id="boxes">
                                                <div className="mw-100 w-100 d-flex mb-3 mb-lg-5 gap-3 gap-lg-5 justify-content-center">
                                                    <svg width="150" height="150" viewBox="0 0 150 150" fill="none"
                                                         xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M144.905 149.805H5.09497C2.33497 149.805 0.0949707 147.565 0.0949707 144.805V5C0.0949707 2.24 2.33497 0 5.09497 0H144.9C147.66 0 149.9 2.24 149.9 5V144.805C149.905 147.57 147.665 149.805 144.905 149.805ZM45.095 109.805H104.9C107.66 109.805 109.9 107.565 109.9 104.805V45C109.9 42.24 107.66 40 104.9 40H45.095C42.335 40 40.095 42.24 40.095 45V104.805C40.095 107.57 42.335 109.805 45.095 109.805Z"
                                                              fill="#4F143B"/>
                                                    </svg>
                                                    <svg className="position-relative" ref={el} width="150" height="150"
                                                         viewBox="0 0 150 150" fill="none"
                                                         xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M144.905 149.905H5.09497C2.33497 149.905 0.0949707 147.665 0.0949707 144.905V5.09479C0.0949707 2.33479 2.33497 0.0947876 5.09497 0.0947876H144.905C147.665 0.0947876 149.905 2.33479 149.905 5.09479V144.9C149.905 147.665 147.665 149.905 144.905 149.905ZM45.095 109.905H104.905C107.665 109.905 109.905 107.665 109.905 104.905V45.0948C109.905 42.3348 107.665 40.0948 104.905 40.0948H45.095C42.335 40.0948 40.095 42.3348 40.095 45.0948V104.9C40.095 107.665 42.335 109.905 45.095 109.905Z"
                                                              fill="#F2637B"/>
                                                    </svg>
                                                </div>
                                                <div className="mw-100 w-100 d-flex mb-3 mb-lg-5 gap-3 gap-lg-5 justify-content-center">
                                                    <svg width="150" height="150" viewBox="0 0 150 150" fill="none"
                                                         xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M144.905 149.805H5.09497C2.33497 149.805 0.0949707 147.565 0.0949707 144.805V5C0.0949707 2.24 2.33497 0 5.09497 0H144.9C147.66 0 149.9 2.24 149.9 5V144.805C149.905 147.57 147.665 149.805 144.905 149.805ZM45.095 109.805H104.9C107.66 109.805 109.9 107.565 109.9 104.805V45C109.9 42.24 107.66 40 104.9 40H45.095C42.335 40 40.095 42.24 40.095 45V104.805C40.095 107.57 42.335 109.805 45.095 109.805Z"
                                                              fill="#4F143B"/>
                                                    </svg>
                                                    <svg width="150" height="150" viewBox="0 0 150 150" fill="none"
                                                         xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M144.905 149.805H5.09497C2.33497 149.805 0.0949707 147.565 0.0949707 144.805V5C0.0949707 2.24 2.33497 0 5.09497 0H144.9C147.66 0 149.9 2.24 149.9 5V144.805C149.905 147.57 147.665 149.805 144.905 149.805ZM45.095 109.805H104.9C107.66 109.805 109.9 107.565 109.9 104.805V45C109.9 42.24 107.66 40 104.9 40H45.095C42.335 40 40.095 42.24 40.095 45V104.805C40.095 107.57 42.335 109.805 45.095 109.805Z"
                                                              fill="#4F143B"/>
                                                    </svg>
                                                </div>
                                                <div id="score" className="position-absolute">
                                                    <div className="dynamic-tooltip dynamic-tooltip--right">
                                                        <div className="dynamic-tooltip__inner"><p>This is your share:</p>
                                                            <h6>- {negativeCalculation.toLocaleString(langSeparator(language), {maximumFractionDigits: 0})} {translations[language].general.currency}
                                                            </h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    );
};

export default Impact;
