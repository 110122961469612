import React, {useContext} from 'react';
import {hideSwiper} from '../../../hooks/helperFunctions';
import {Settings} from '../../../store';

const SwiperNext = (props) => {
    const {swiper, name, checkboxAlert, businessValue, showCheckboxModal, negativeImpact, setInvalidValidation} = props;
    const dispatch = useContext(Settings.Dispatch);


    const handleClick = () => {
        if ((swiper.activeIndex === 1 && businessValue === 0) || swiper.activeIndex === 2) {
            dispatch({type: 'setFinalResult', payload: true});
        }
        if (negativeImpact !== undefined && negativeImpact.includes('B.') && checkboxAlert && swiper.activeIndex === 1) {
            showCheckboxModal();
            setInvalidValidation(true)

        } else {
            swiper.slideTo(swiper.activeIndex + 1);
        }

    };


    return <div className={'text-center'}>
        <button className="btn btn-rounded btn-lg btn-primary px-6"
                onClick={handleClick}>{name}
        </button>
    </div>;
};
export default SwiperNext;