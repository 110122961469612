import React from 'react';
import {useEffect, useState} from 'react';
import TagManager from '../services/tag-manager';
import {hotjar} from 'react-hotjar';

export const usePageTracking = () => {

    const [initialized, setInitialized] = useState(false);
    const hostnameParts = window.location.hostname.split('.');

    let trackingID = 'GTM-MST5FSJ';
    let country = 'Norway';
    if (hostnameParts[hostnameParts.length - 1] === 'se') {
        country = 'Sweden';
    }
    if (hostnameParts[hostnameParts.length - 1] === 'com') {
        country = 'Global';
    }
    const tagManagerArgs = {
        gtmId: trackingID,
        dataLayer: {
            userProject: hostnameParts[0] === 'footprint' ? 'Footprint v2' : 'Impact',
            event: 'metadata',
            pageCategory: hostnameParts[0] === 'footprint' ? 'Footprint v2' : 'Impact',
            country: country,
        },
    };

    useEffect(() => {
        if (!initialized) {
            TagManager.initialize(tagManagerArgs);
            setInitialized(true);
        }
    }, []);

    // useEffect(() => {
    //     if (hostnameParts[hostnameParts.length - 1] === 'no') {
    //         hotjar.initialize('1622560', 6);
    //     }
    // }, []);
    return hostnameParts[0];
};