import React, {useContext, useEffect, useState} from 'react';
import {Settings} from '../../../store';
import TalkToExpert from './TalkToExpert';
import TagManager from 'react-gtm-module';
import Widget from '../widgets/Widget';
import translations from '../../../data/footprint/lang.json';
const CTABlock = (props) => {
    const settingsState = useContext(Settings.State);
    const {showContactModal, isContactOpen, setIsCalendlyOpen, isCalendlyOpen, setIsContactOpen} = props;
    const {language} = settingsState;


    const [link, setLink] = useState('https://footprint.formue.no/');

    const hideContactModal = () => {
        setIsContactOpen(false);
    };
    const showCalendlyModal = () => {
        setIsCalendlyOpen(true);
    };
    const hideCalendlyModal = () => {
        setIsCalendlyOpen(false);
    };
    useEffect(() => {
        switch (language) {
            case 'no':
                setLink('https://footprint.formue.no/');
                break;
            case 'se':
                setLink('https://footprint.formue.se/');
                break;
            case 'com':
                setLink('https://footprint.formue.com/');
                break;
            default:
                setLink('https://footprint.formue.no/');

        }
    }, [language]);

    return (<>

                <button className="btn btn-rounded btn-lg btn-primary" onClick={(e) => {
                    e.preventDefault();
                    TagManager.dataLayer({
                        dataLayer: {
                            userProject: 'Footprint v2',
                            event: 'CTAevent',
                            CTAaction: 'Contact us - footer',
                        },
                    });
                    showContactModal();
                }}>{translations[language].whatcanyoudo.popup.button}
                </button>
                <Widget isCalendlyOpen={isCalendlyOpen} showContactModal={showContactModal}
                        hideContactModal={hideContactModal} isContactOpen={isContactOpen}
                        showCalendlyModal={showCalendlyModal} hideCalendlyModal={hideCalendlyModal}/>


            </>
    );
};

export default CTABlock;
