import React, {useContext, useState, useEffect} from 'react';
import SwiperNext from './swiper/SwiperNext';
import {Settings} from '../../store';
import Task from './Task';
import translations from '../../data/footprint/lang.json';
import {gsap} from 'gsap';
import Modal from 'react-bootstrap/Modal';
import PFData from '../../data/footprint/personalFootprint.json';
import VerticalLifeStyleLine from './VerticalLifeStyleLine';
import {langSeparator} from '../../hooks/helperFunctions';

const LifeStyle = (props) => {
            const backgroundUrl = '../media/lifestyle-lg.svg';
            const {swiper} = props;
            const settingsState = useContext(Settings.State);
            const {lifeStyleValue, language, incomeLevel} = settingsState;
            const [taskList, setTaskList] = useState([]);
            const [PFMaxVal, setPFMaxVal] = useState(PFData[language][incomeLevel].maxVal);
            const [PFRest, setPFRest] = useState(PFData[language][incomeLevel].rest);
            const [isOpen, setIsOpen] = useState(false);
            const showModal = (e) => {
                e.preventDefault();
                setIsOpen(true);
            };

            const hideModal = () => {
                setIsOpen(false);
            };
            useEffect(() => {
                setTaskList(translations[language].action_list_1.items);
            }, [language]);

            useEffect(() => {
                gsap.set('#animatedThumbup', {
                    opacity: 0,
                    scale: 0,
                    rotate: -90,
                });
            }, []);

            useEffect(() => {
                setPFMaxVal(PFData[language][incomeLevel].maxVal);
                setPFRest(PFData[language][incomeLevel].rest);
            }, [incomeLevel, language]);


            return (<>
                        <div className="block__content">
                            <img className="top-img m-auto d-block position-absolute start-50 translate-middle-x"
                                 src={backgroundUrl} alt="My Image"/>
                            <div className="position-absolute start-0 end-0 z-n1">
                                <svg width="2440" height="467" viewBox="0 0 2440 467" fill="none"
                                     xmlns="http://www.w3.org/2000/svg"
                                     className="position-absolute top-0 start-50 translate-middle-x h-auto "
                                     style={{width: '130vw'}}>
                                    <g clip-path="url(#clip0_1733_12101)">
                                        <path d="M1259.07 1.02893C1674.94 5.57879 2031.82 22.8767 2031.82 22.8767L2440 55.9526C2440 55.9526 2440 47.9593 2440 135.441V467H0V135.41C0.000284209 90.9229 0 82.9297 0 82.9297C102.783 71.3766 338.14 45.3852 397.354 38.3634C661.823 6.99785 993.403 -0.056903 1165.51 0.000344494C1198.06 0.361454 1229.21 0.702284 1259.07 1.02893Z"
                                              fill="url(#paint0_linear_1733_12101)"/>
                                    </g>
                                    <defs>
                                        <linearGradient id="paint0_linear_1733_12101" x1="1220" y1="0" x2="1220" y2="467"
                                                        gradientUnits="userSpaceOnUse">
                                            <stop stop-color="#F7E8EA"/>
                                            <stop offset="1" stop-color="#FBF3F5"/>
                                        </linearGradient>
                                        <clipPath id="clip0_1733_12101">
                                            <rect width="2440" height="467" fill="white"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                            <div className="container position-relative py-9 pb-6">
                                <svg width="2440" height="467" viewBox="0 0 2440 467" fill="none"
                                     xmlns="http://www.w3.org/2000/svg"
                                     className="position-absolute top-0 start-50 translate-middle-x h-auto z-n1"
                                     style={{width: '130vw'}}>
                                    <g clip-path="url(#clip0_1733_12101)">
                                        <path opacity="0.5"
                                              d="M1259.07 1.02893C1674.94 5.57879 2031.82 22.8767 2031.82 22.8767L2440 55.9526C2440 55.9526 2440 47.9593 2440 135.441V467H0V135.41C0.000284209 90.9229 0 82.9297 0 82.9297C102.783 71.3766 338.14 45.3852 397.354 38.3634C661.823 6.99785 993.403 -0.056903 1165.51 0.000344494C1198.06 0.361454 1229.21 0.702284 1259.07 1.02893Z"
                                              fill="url(#paint0_linear_1733_12101)"/>
                                    </g>
                                    <defs>
                                        <linearGradient id="paint0_linear_1733_12101" x1="1220" y1="0" x2="1220" y2="467"
                                                        gradientUnits="userSpaceOnUse">
                                            <stop stop-color="#F1D5D9"/>
                                            <stop offset="1" stop-color="#F9F6FB"/>
                                        </linearGradient>
                                        <clipPath id="clip0_1733_12101">
                                            <rect width="2440" height="467" fill="white"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                                <div className="row justify-content-center">
                                    <div className="col-lg-12 col-xl-10 d-flex flex-column gap-6">
                                        <div className="row justify-content-center text-center">
                                            <div className="col-sm-10 col-lg-9 col-xl-7 d-flex flex-column gap-4">
                                                <div className="d-flex flex-row align-items-baseline justify-content-center">
                                                    <h4 className="large footprint-value mt-3">+{lifeStyleValue.toLocaleString(langSeparator(language), {maximumFractionDigits: 1})}
                                                    </h4><strong class="ms-2 small"> tCO<sub>2</sub></strong>
                                                </div>
                                                <h2 className="">{translations[language].lifestyleSection.title}</h2>
                                                <p className="mb-0 mb-lg-5 preamble">{translations[language].lifestyleSection.text}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="container position-relative pb-6 pb-lg-9">
                                <div className="row justify-content-center">
                                    <div className="col-lg-8">
                                        <div className="rounded bg-light mb-lg-0 mb-5 overflow-hidden"
                                             style={{flex: '1 0'}}>
                                            <div className="row justify-content-center p-5 pt-md-7 pb-lg-6 pt-lg-8 px-5 px-md-7">
                                                <div className="col-lg-10 ">
                                                    <div className="text-center d-flex gap-2 flex-column">
                                                        <h4 className="">{translations[language].lifestyleSection.examplesSubtext}</h4>
                                                        <p>{translations[language].lifestyleSection.distributionSubtext}
                                                            <strong> {lifeStyleValue.toLocaleString(langSeparator(language), {maximumFractionDigits: 1})} tCO<sub>2</sub></strong>
                                                        </p>
                                                    </div>

                                                    <div className="row flex-nowrap gap-2 overflow-auto text-center pb-3">
                                                        {Object.keys(PFRest).map(key => {
                                                            return <VerticalLifeStyleLine type={'pf-label'} key={key}
                                                                                          maxVal={PFMaxVal}
                                                                                          value={PFRest[key]}
                                                                                          name={key} language={language}/>;
                                                        })}
                                                    </div>
                                                    {/*<div className="row mt-4 justify-content-center text-center">*/}
                                                    {/*    <div className="col-sm-10 col-md-8">*/}
                                                    {/*        <a role="button" onClick={showModal} href="#" className="mt-5 small"*/}
                                                    {/*           dangerouslySetInnerHTML={{__html: translations[language].lifestyle_inputs.howwestimate}}>*/}
                                                    {/*        </a>*/}
                                                    {/*    </div>*/}
                                                    {/*</div>*/}
                                                </div>

                                            </div>
                                            <div className="row justify-content-center p-md-6 pt-md-7 pb-lg-7 pt-lg-8 p-5 border-top">
                                                <div className="col-lg-9">
                                                    <div className="text-center d-flex gap-2 flex-column mb-6">
                                                        <h4>{translations[language].lifestyleSection.examples} </h4>
                                                        {/*<p>{translations[language].lifestyleSection.examplesSubtext}</p>*/}
                                                    </div>

                                                    <div className="">
                                                        <div className="d-flex align-items-center justify-content-between mb-2 p-3 border-bottom">
                                                            <p className="m-0 font-weight-medium">{translations[language].lifestyleSection.potentialFootprint}</p>
                                                            <div className="d-flex align-items-baseline">
                                                                <strong className="preamble">+{lifeStyleValue.toLocaleString(langSeparator(language), {maximumFractionDigits: 1})}</strong>
                                                                <strong className="ms-2 small">tCO<sub>2</sub></strong>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex align-items-center justify-content-between mb-2 p-3 border-bottom">
                                                            <p className="d-flex m-0 align-items-center ">
                                                                <svg className="me-lg-3 me-2" width="24" height="24"
                                                                     viewBox="0 0 24 24" fill="none"
                                                                     xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M11.029 11.6226C8.4952 18.1284 2.89 17.7225 0.944498 17.4195C0.762398 17.391 0.600098 17.5317 0.600098 17.7159V19.5669C0.600098 19.7154 0.707198 19.8432 0.854198 19.8642C1.4308 19.947 1.7656 19.9968 2.4403 19.9968C6.253 19.9968 10.9756 18.4302 13.2847 12.5013C15.7837 6.08583 21.0082 6.08283 23.0734 6.28623C23.2489 6.30333 23.4001 6.16383 23.4001 5.98743V4.17603C23.4001 4.01853 23.2801 3.88773 23.1229 3.87663C20.3494 3.67803 14.0137 3.95943 11.029 11.6226Z"
                                                                          fill="#F2637B"/>
                                                                    <path d="M13.9882 13.6695C13.6864 14.271 13.3489 14.9322 12.9493 15.6588C12.8893 15.7677 12.9004 15.9063 12.9805 16.0011C15.9469 19.512 19.8478 20.121 22.3939 20.121C22.777 20.121 22.8223 20.1072 23.1202 20.0877C23.2786 20.0772 23.4001 19.9467 23.4001 19.788V17.9775C23.4001 17.8002 23.248 17.6631 23.0716 17.679C21.2047 17.8473 17.0644 17.8083 14.2447 13.6569C14.1802 13.5618 14.0395 13.5669 13.9882 13.6695Z"
                                                                          fill="#A0496D"/>
                                                                    <path d="M11.242 8.18515C8.1268 4.28695 3.9667 3.71755 0.857198 4.13215C0.708698 4.15195 0.600098 4.28035 0.600098 4.43005V6.27955C0.600098 6.46195 0.759698 6.60265 0.940298 6.57655C2.7646 6.31225 7.1485 6.06565 9.9523 10.5489C10.015 10.6491 10.1617 10.6443 10.2145 10.5387C10.5916 9.78325 10.9606 9.08245 11.2705 8.51935C11.3293 8.41225 11.3179 8.28025 11.242 8.18515Z"
                                                                          fill="#A0496D"/>
                                                                </svg>

                                                                <span>{translations[language].lifestyle_accordion.line1}</span>
                                                            </p>

                                                            <div className="d-flex align-items-baseline">
                                                                <strong className="preamble">-{(1.6).toLocaleString(langSeparator(language), {maximumFractionDigits: 2})}</strong>
                                                                <strong className="ms-2 small">tCO<sub>2</sub></strong>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex align-items-center justify-content-between mb-2 p-3 border-bottom">
                                                            <p className="d-flex align-items-center m-0">
                                                                <svg className="me-lg-3 me-2" width="24" height="24"
                                                                     viewBox="0 0 24 24" fill="none"
                                                                     xmlns="http://www.w3.org/2000/svg">
                                                                    <g clip-path="url(#clip0_1480_20181)">
                                                                        <path d="M5.50876 24.0282H3.70276C3.52216 24.0282 3.40186 23.9079 3.40186 23.7273V21.9213C3.40186 21.7407 3.52216 21.6204 3.70276 21.6204H5.50876C5.68936 21.6204 5.80966 21.7407 5.80966 21.9213V23.7273C5.80966 23.9079 5.65906 24.0282 5.50876 24.0282Z"
                                                                              fill="#45A2FF"/>
                                                                        <path d="M20.6602 11.0598C20.4796 10.6686 20.1787 9.97624 19.8775 9.19384C19.1851 7.41814 17.9212 6.51514 16.0852 6.51514H8.19945C6.36345 6.51514 5.09925 7.41814 4.40715 9.19384C4.10625 9.94624 3.80505 10.6686 3.62445 11.0598C2.90205 11.5413 1.42725 12.7755 1.42725 14.6115V18.1029C1.42725 19.5477 2.72145 20.4204 3.98565 20.4204H20.269C21.5332 20.4204 22.8274 19.5474 22.8274 18.1029V14.5812C22.8574 12.7452 21.3823 11.5413 20.6602 11.0598ZM8.19945 8.92294H16.1152C16.9279 8.92294 17.3191 9.19384 17.6503 10.0665C17.7706 10.3674 17.8912 10.6686 18.0115 10.9395C17.59 11.1201 17.1085 11.2707 16.657 11.2707H7.65765C7.23615 11.2707 6.72465 11.1201 6.30315 10.9395C6.42345 10.6686 6.54405 10.3677 6.66435 10.0665C6.99555 9.22384 7.35675 8.92294 8.19945 8.92294ZM20.299 18.0126H4.01565C3.95535 18.0126 3.86505 18.0126 3.86505 17.9223C3.86505 17.832 3.86505 16.959 3.86505 16.959C4.13595 17.0193 4.37685 17.0193 4.55745 17.0193H7.35675C7.53735 17.0193 7.65765 16.899 7.65765 16.7184V14.9124C7.65765 14.7318 7.53735 14.6115 7.35675 14.6115H4.55745C4.37685 14.6115 4.10595 14.5512 3.86505 14.4912C3.95535 13.8591 4.64775 13.2873 5.03895 13.0164C5.73135 13.3173 6.69435 13.6785 7.65765 13.6785H16.627C17.59 13.6785 18.5833 13.3473 19.2457 13.0164C19.6069 13.2573 20.3293 13.8591 20.4196 14.4912C20.209 14.5515 19.9078 14.6115 19.7272 14.6115H16.9279C16.7473 14.6115 16.627 14.7318 16.627 14.9124V16.7184C16.627 16.899 16.7473 17.0193 16.9279 17.0193H19.7272C19.9078 17.0193 20.1487 16.9893 20.4196 16.959C20.4196 16.959 20.4196 17.7114 20.4196 17.862C20.4493 18.0126 20.359 18.0126 20.299 18.0126Z"
                                                                              fill="#4C68A0"/>
                                                                        <path d="M18.5231 24.0282H20.3291C20.5097 24.0282 20.63 23.9079 20.63 23.7273V21.9213C20.63 21.7407 20.5097 21.6204 20.3291 21.6204H18.5231C18.3425 21.6204 18.2222 21.7407 18.2222 21.9213V23.7273C18.2222 23.9079 18.3428 24.0282 18.5231 24.0282Z"
                                                                              fill="#45A2FF"/>
                                                                        <path d="M3.44825 6.31417C3.31025 6.08797 3.14285 5.24917 4.25645 4.64737C5.02415 4.22377 5.69885 4.63867 6.03455 4.90837C6.10595 4.96567 6.19835 4.98847 6.28685 4.96387C6.40535 4.93117 6.53495 4.90957 6.66455 4.88797C7.23485 4.78867 7.68275 4.71007 8.04155 4.66837C8.16545 4.65397 8.27045 4.56577 8.29805 4.44397C8.69825 2.67577 10.531 2.47867 10.9084 2.44987C13.117 2.31367 13.9918 3.68407 14.2486 4.39057C14.2912 4.50757 14.3989 4.58677 14.5234 4.58677H16.2889C16.4737 4.58677 16.6111 4.41757 16.5757 4.23607C16.1686 2.14807 13.9468 -0.187427 10.7275 0.0120726C8.57795 0.153373 7.27895 1.17187 6.58055 2.21827C6.50345 2.33347 6.36155 2.38087 6.23075 2.33467C5.24615 1.98607 4.04795 1.93597 2.90195 2.69077C0.502554 4.28047 0.822954 6.49747 1.33145 7.43647C1.33565 7.44427 1.33925 7.45057 1.34405 7.45807C1.39685 7.54387 1.74005 8.08537 2.27015 8.52097C2.41505 8.63977 2.63645 8.58847 2.71565 8.41867C2.95655 7.90057 3.23495 7.39567 3.60125 6.89407C3.67565 6.79207 3.67925 6.65497 3.60605 6.55207C3.55835 6.48547 3.50255 6.40327 3.44825 6.31417Z"
                                                                              fill="#464F81"/>
                                                                    </g>
                                                                    <defs>
                                                                        <clipPath id="clip0_1480_20181">
                                                                            <rect width="24" height="24" fill="white"/>
                                                                        </clipPath>
                                                                    </defs>
                                                                </svg>

                                                                <span>{translations[language].lifestyle_accordion.line2}</span>
                                                            </p>
                                                            <div className="d-flex align-items-baseline">
                                                                <strong className="preamble">-{(1.95).toLocaleString(langSeparator(language), {maximumFractionDigits: 2})}</strong>
                                                                <strong className="ms-2 small">tCO<sub>2</sub></strong>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex align-items-center justify-content-between mb-2 p-3">
                                                            <p className="d-flex m-0 align-items-center">
                                                                <svg className="me-3" width="24" height="24"
                                                                     viewBox="0 0 24 24"
                                                                     fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M15.8781 22.2617H17.6781C17.8437 22.2617 17.9781 22.1273 17.9781 21.9617V16.5617C17.9781 16.3961 17.8437 16.2617 17.6781 16.2617H15.8781C15.7125 16.2617 15.5781 16.3961 15.5781 16.5617V21.9617C15.5781 22.1276 15.7122 22.2617 15.8781 22.2617Z"
                                                                          fill="#455E4D"/>
                                                                    <path d="M19.6955 22.2617H21.4955C21.6611 22.2617 21.7955 22.1273 21.7955 21.9617V13.2617C21.7955 13.0961 21.6611 12.9617 21.4955 12.9617H19.6955C19.5299 12.9617 19.3955 13.0961 19.3955 13.2617V21.9617C19.3955 22.1276 19.5299 22.2617 19.6955 22.2617Z"
                                                                          fill="#455E4D"/>
                                                                    <path d="M12.0607 22.2618H13.8607C14.0263 22.2618 14.1607 22.1274 14.1607 21.9618V19.8618C14.1607 19.6962 14.0263 19.5618 13.8607 19.5618H12.0607C11.8951 19.5618 11.7607 19.6962 11.7607 19.8618V21.9618C11.7607 22.1277 11.8951 22.2618 12.0607 22.2618Z"
                                                                          fill="#455E4D"/>
                                                                    <path d="M0.928445 8.42589L11.8295 0.903686C11.9321 0.832886 12.0677 0.832886 12.1703 0.903686L23.0714 8.42619C23.2079 8.52039 23.2421 8.70699 23.1479 8.84349L22.1255 10.3249C22.0313 10.4614 21.8447 10.4956 21.7082 10.4014L12.1703 3.81939C12.0677 3.74859 11.9321 3.74859 11.8295 3.81939L2.29165 10.4014C2.15515 10.4956 1.96855 10.4611 1.87435 10.3249L0.851945 8.84319C0.757745 8.70699 0.792245 8.52009 0.928445 8.42589Z"
                                                                          fill="#567E59"/>
                                                                    <path d="M2.18213 22.2618H4.58213V16.0158C4.58213 14.6952 5.10323 13.5684 6.13163 12.6669C7.37303 11.5782 9.26573 10.926 11.0618 10.926C11.0621 10.926 11.0621 10.926 11.0624 10.926C11.1686 12.5331 10.826 14.4459 9.76283 15.7281C8.87513 16.7982 7.55783 17.3412 5.84813 17.3412V19.7412C8.27153 19.7412 10.2641 18.8835 11.6102 17.2599C13.502 14.9787 13.7066 11.6931 13.3073 9.57245L13.1465 8.71835L12.2849 8.60465C9.56003 8.24675 6.52283 9.13175 4.54943 10.8618C3.00083 12.2196 2.18213 14.0019 2.18213 16.0155V22.2618Z"
                                                                          fill="#6ECA5B"/>
                                                                </svg>

                                                                <span>{translations[language].lifestyle_accordion.line3}</span>
                                                            </p>
                                                            <div className="d-flex align-items-baseline">
                                                                <strong className="preamble">-{(1.7).toLocaleString(langSeparator(language), {maximumFractionDigits: 2})}</strong>
                                                                <strong className="ms-2 small">tCO<sub>2</sub></strong>
                                                            </div>
                                                        </div>

                                                        <div className="d-flex align-items-center justify-content-between position-relative gap-5 p-3 bg-dark">
                                                            <p className="m-0 font-weight-medium">{translations[language].lifestyleSection.potentialClimateFootprint}</p>
                                                            <div className="d-flex align-items-baseline">
                                                                <h4 className="m-0">
                                                                    {lifeStyleValue >= 5.25 && "+"} {(lifeStyleValue - 5.25 ).toLocaleString(langSeparator(language), {maximumFractionDigits: 2})}
                                                                </h4>
                                                                <strong className="ms-2 small">tCO<sub>2</sub></strong>
                                                            </div>
                                                            <img id="animatedThumbup"
                                                                 className="position-absolute start-100 ms-2"
                                                                 src={'../media/svg/thumbup.svg'} alt="thumbup"/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="container position-relative py-6 py-lg-9">
                                <svg width="2440" height="467" viewBox="0 0 2440 467" fill="none"
                                     xmlns="http://www.w3.org/2000/svg"
                                     className="position-absolute top-0 start-50 translate-middle-x h-auto z-n1"
                                     style={{width: '130vw'}}>
                                    <g clip-path="url(#clip0_1733_12101)">
                                        <path opacity="0.5"
                                              d="M1259.07 1.02893C1674.94 5.57879 2031.82 22.8767 2031.82 22.8767L2440 55.9526C2440 55.9526 2440 47.9593 2440 135.441V467H0V135.41C0.000284209 90.9229 0 82.9297 0 82.9297C102.783 71.3766 338.14 45.3852 397.354 38.3634C661.823 6.99785 993.403 -0.056903 1165.51 0.000344494C1198.06 0.361454 1229.21 0.702284 1259.07 1.02893Z"
                                              fill="url(#paint0_linear_1733_12101)"/>
                                    </g>
                                    <defs>
                                        <linearGradient id="paint0_linear_1733_12101" x1="1220" y1="0" x2="1220" y2="467"
                                                        gradientUnits="userSpaceOnUse">
                                            <stop stop-color="#F1D5D9"/>
                                            <stop offset="1" stop-color="#F9F6FB"/>
                                        </linearGradient>
                                        <clipPath id="clip0_1733_12101">
                                            <rect width="2440" height="467" fill="white"/>
                                        </clipPath>
                                    </defs>
                                </svg>


                                <div className="row justify-content-center">
                                    <div className="col-lg-8 d-flex flex-column gap-6 gap-lg-7">

                                        <div className="row justify-content-center text-center">
                                            <div className="col-md-10 col-lg-8 col-xl-6 d-flex flex-column gap-4">
                                                <h2>{translations[language].lifestyleSection.tasksTitle}</h2>
                                                <p className="preamble m-0">{translations[language].lifestyleSection.tasksSubtitle}</p>
                                            </div>
                                        </div>

                                        <div className="row row-cols-1 g-2">

                                            {taskList.map((item) => {
                                                return <Task
                                                        key={item.id} item={item} type={'lifestyle'}/>;
                                            })}

                                        </div>

                                        <div className="row justify-content-center">
                                            <div className="col-lg-12 col-xl-10">
                                                <SwiperNext swiper={swiper} name={translations[language].general.next}/>
                                            </div>
                                        </div>
                                        {/*<div className="row">*/}
                                        {/*    <div className="col">*/}
                                        {/*        <p className="mt-5 small"*/}
                                        {/*           dangerouslySetInnerHTML={{__html: translations[language].lifestyleSection.diagramText2}}>*/}
                                        {/*        </p>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Modal size="md" dialogClassName={'modal-fullscreen-md-down'}
                               aria-labelledby="contained-modal-title-vcenter"
                               centered show={isOpen} onHide={hideModal}>
                            <div className="modal-content">
                                <div className="modal-header">
                                    <button onClick={hideModal} type="button" className="btn-close" data-bs-dismiss="modal"
                                            aria-label="Close"></button>
                                </div>
                                <div className="modal-body"
                                     dangerouslySetInnerHTML={{__html: translations[language].lifestyle_inputs.popup}}>
                                </div>
                            </div>
                        </Modal>
                    </>
            );
        }
;
export default LifeStyle;
