import React, {useContext} from 'react';
import translations from '../../data/footprint/lang.json';
import {Settings} from '../../store';
import Offcanvas from 'react-bootstrap/Offcanvas';
import CartTask from './CartTask';
import {putItemFirst} from '../../hooks/helperFunctions'

const OffcanvasMenu = (props) => {

    const {show, handleClose,showContactModal} = props;
    const settingsState = useContext(Settings.State);
    const {activeTasks, language, engagement, sustainableShare, negativeImpact} = settingsState;


    const activeList1 = activeTasks.filter((item) => {
        return item.category === translations[language].menuitems.item1;
    });
    const activeList2 = activeTasks.filter((item) => {
        return item.category === translations[language].menuitems.item2;
    });
    const activeList3 = activeTasks.filter((item) => {
        return item.category === translations[language].menuitems.item3;
    });

    putItemFirst(activeList3, "kanbanitem144")



    return (<Offcanvas show={show} onHide={handleClose} placement={'end'} backdropClassName="modal-backdrop"
                       className="offcanvas--cart">
        <Offcanvas.Header className="p-5" closeButton>
            <Offcanvas.Title><h4>{translations[language].yourfootprint}</h4></Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-5">
            {activeTasks.length === 0 && <h6>{translations[language].nothinghere}</h6> }
                            {/*&& engagement === '' && sustainableShare === '' && negativeImpact === '') */}

            {/*{(engagement !== '' || sustainableShare !== '' || negativeImpact !== '') &&*/}
            {/*        <h6 className="cat-title mb-3">{translations[language].menuitems.item4}</h6>}*/}
            {/*{sustainableShare !== '' && <CartTask type={'investments'} id={'sustainableShare'} content={sustainableShare} icon={'Investment_Preferences.svg'}/>}*/}
            {/*{engagement !== '' && <CartTask type={'investments'} id={'engagement'} content={engagement} icon={'Investment_Preferences.svg'}/>}*/}
            {/*{negativeImpact !== '' && <CartTask type={'investments'} id={'negativeImpact'} content={negativeImpact} icon={'Investment_Preferences.svg'}/>}*/}

            {activeList1.length !== 0 && <h6 className="cat-title mb-3">{translations[language].menuitems.item1}</h6>}
            {activeList1.map((item) => {
                return <CartTask showContactModal={showContactModal} id={item.id} popup={item.popup} content={item.content} icon={item.icon}/>;
            })}{activeList3.length !== 0 && <h6 className="cat-title mb-3">{translations[language].menuitems.item3}</h6>}
            {activeList3.map((item) => {
                return <CartTask showContactModal={showContactModal} id={item.id} popup={item.popup} content={item.content} icon={item.icon}/>;
            })}

        </Offcanvas.Body>
    </Offcanvas>);

};

export default OffcanvasMenu;