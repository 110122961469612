import React, {useContext} from 'react';
import {Settings} from '../../../store';
import translations from '../../../data/footprint/lang.json';

const SwiperBack = () => {
    const dispatch = useContext(Settings.Dispatch);
    const settingsState = useContext(Settings.State);
    const {language} = settingsState;
    return <button className="btn btn-link btn--svg d-flex gap-1 px-2 z-1 ps-md-3 pe-md-4" onClick={() => {
        dispatch({type: 'setIsSwiperModalOpen', payload: false});
        dispatch({type: 'setFinalResult', payload: false});
        dispatch({type: 'setActiveSlide', payload: 'lifestyle-block'});

    }}>
        <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <mask id="a" style={{maskType: 'alpha'}} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                <path fill="#D9D9D9" d="M0 0h24v24H0z"/>
            </mask>
            <g mask="url(#a)">
                <path d="m12 13.4-4.9 4.9a.948.948 0 0 1-.7.275.948.948 0 0 1-.7-.275.948.948 0 0 1-.274-.7c0-.283.091-.517.275-.7l4.9-4.9-4.9-4.9a.948.948 0 0 1-.275-.7c0-.283.091-.517.275-.7a.948.948 0 0 1 .7-.275c.283 0 .516.092.7.275l4.9 4.9 4.9-4.9a.948.948 0 0 1 .7-.275c.283 0 .516.092.7.275a.948.948 0 0 1 .275.7.948.948 0 0 1-.275.7L13.4 12l4.9 4.9a.948.948 0 0 1 .275.7.948.948 0 0 1-.275.7.949.949 0 0 1-.7.275.948.948 0 0 1-.7-.275L12 13.4Z"
                      fill="#2F3A71"/>
            </g>
        </svg>
        <span className="d-none d-md-inline-block">{translations[language].general.close}</span>
    </button>;
};

export default SwiperBack;