import React, {useContext, useRef, useEffect} from 'react';
import {gsap} from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import {Settings} from '../../../store';
import Modal from 'react-bootstrap/Modal';
import {NumericFormat} from 'react-number-format';
import translations from '../../../data/footprint/lang.json';

const SwiperGroup = (props) => {
    gsap.registerPlugin(ScrollTrigger);
    const {
        isOpen, hideModal, separator, updateCompanyRevenue, handleKeyPress,
    } = props;
    const el = useRef(null);
    const subBut = useRef(null);
    let ref = useRef();
    const settingsState = useContext(Settings.State);
    const dispatch = useContext(Settings.Dispatch);
    const MAX_LIMIT = 1000000000000;
    const {
        language,
        businessSubIndustry,
        companyRevenue,
        activeCurrencyLang
    } = settingsState;

    useEffect(() => {
        if (el.current !== null) {
            setTimeout(() => {
                el.current.focus();
            }, 500);
        }
    }, [el]);

    return (<Modal size="sm"
                   aria-labelledby="contained-modal-title-vcenter"
                   centered show={isOpen} onHide={hideModal}
                   className="business-dropdown text-white"

    >
        <h3 className="text-center mb-5">{translations[language].screen2.enterRevenueCompany}</h3>
        <div className="modal-body p-0">
            <div className="position-relative">

                <NumericFormat disabled={businessSubIndustry === '' && `disabled`}
                               value={companyRevenue !== 0 ? companyRevenue : ''}
                               pattern="[0-9]*"
                               thousandSeparator={separator}
                               onKeyDown={handleKeyPress}
                               id="company-revenue"
                               getInputRef={(inputRef) => {
                                   ref = inputRef;
                                   setTimeout(() => {
                                       if (ref == null) return;
                                       ref.focus();
                                   }, 500);
                               }}
                               className="form-control"
                               placeholder={translations[language].screen2.enterRevenue}
                               required
                               onValueChange={(value, sourceInfo) => {
                                   updateCompanyRevenue(value);
                                   dispatch({type: 'setLockCounterUp', payload: false});
                               }}
                               isAllowed={(values, sourceInfo) => {
                                   const {value} = values;
                                   return value < MAX_LIMIT;
                               }}
                               suffix={' ' + translations[language].general.millions + ' ' + translations[activeCurrencyLang].general.currency}
                />
                <button ref={subBut} onClick={hideModal} role="button" tabIndex="-1"
                        className={companyRevenue ? 'd-flex align-items-center justify-content-center business-dropdown--submit active' : 'd-flex align-items-center justify-content-center business-dropdown--submit'}>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <mask id="mask0_1255_14769" style={{maskType: 'alpha'}} maskUnits={'userSpaceOnUse'} x="0" y="0"
                              width="20" height="20">
                            <rect width="20" height="20" fill="#D9D9D9"/>
                        </mask>
                        <g mask="url(#mask0_1255_14769)">
                            <path d="M11.226 14.2388C11.0828 14.0956 11.0099 13.938 11.0072 13.766C11.0045 13.594 11.0748 13.4364 11.2179 13.2933L13.8446 10.6666H4.24837C4.0713 10.6666 3.91593 10.5998 3.78225 10.466C3.64857 10.3323 3.58173 10.1769 3.58173 9.99971C3.58173 9.82255 3.64857 9.66719 3.78225 9.53365C3.91593 9.40011 4.0713 9.33334 4.24837 9.33334H13.8446L11.1971 6.68586C11.0732 6.56336 11.0112 6.41441 11.0112 6.23902C11.0112 6.06364 11.0828 5.90436 11.226 5.76121C11.3552 5.63193 11.5072 5.56382 11.6819 5.55688C11.8565 5.54993 12.0155 5.61805 12.1586 5.76121L15.8285 9.43109C15.9204 9.52296 15.9858 9.616 16.0248 9.71019C16.0638 9.80439 16.0833 9.90134 16.0833 10.001C16.0833 10.1008 16.0638 10.1974 16.0248 10.2908C15.9858 10.3843 15.9204 10.477 15.8285 10.5689L12.1378 14.2596C11.9973 14.4028 11.8425 14.4674 11.6735 14.4535C11.5044 14.4396 11.3552 14.368 11.226 14.2388Z"
                                  fill="currentColor"/>
                        </g>
                    </svg>

                </button>
            </div>
        </div>
    </Modal>);
};
export default SwiperGroup;