import Modal from 'react-bootstrap/Modal';
import {removeCartItem} from '../../../hooks/helperFunctions';
import {useContext} from 'react';
import {Settings} from '../../../store';
import translations from '../../../data/footprint/lang.json';

const DeleteModal = (props) => {
    const {
        isOpen, hideModal, element, type
    } = props;
    const settingsState = useContext(Settings.State);
    const dispatch = useContext(Settings.Dispatch);
    const {activeTasks,language} = settingsState;
    if(type === 'investments') {
    return (<Modal size="sm"
                   aria-labelledby="contained-modal-title-vcenter"
                   centered show={isOpen} onHide={hideModal}
    >
        <div className="modal-header">
            <button onClick={hideModal} type="button" className="btn-close" data-bs-dismiss="modal"
                    aria-label="Close"></button>
        </div>
        <div className="modal-body p-6 text-center d-flex flex-column gap-4">
            <div>
                <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M19.2037 50.1187H2.15312C1.76673 50.1187 1.45312 49.8051 1.45312 49.4187V24.7577C1.45312 24.3713 1.76673 24.0577 2.15312 24.0577H19.2065C19.3976 24.0577 19.5803 24.1361 19.7126 24.274L25.2881 30.1099C25.5555 30.3892 25.545 30.8323 25.2657 31.0997L22.2291 34.0005C21.9498 34.2672 21.5067 34.2574 21.2393 33.9781L17.3179 29.874C17.1856 29.7361 17.0029 29.6577 16.8118 29.6577H7.75312C7.36672 29.6577 7.05312 29.9713 7.05312 30.3577V43.8187C7.05312 44.2051 7.36672 44.5187 7.75312 44.5187H16.816C17.0064 44.5187 17.1891 44.441 17.3214 44.3031L21.2421 40.2165C21.5095 39.9372 21.9526 39.9281 22.2319 40.1962L25.2622 43.104C25.5408 43.3714 25.5506 43.8145 25.2825 44.0938L19.7084 49.9031C19.5768 50.041 19.3941 50.1187 19.2037 50.1187Z"
                          fill="#A0496D"/>
                    <path d="M53.8487 50.1296H36.7967C36.6056 50.1296 36.4229 50.0519 36.2913 49.914L30.7165 44.0921C30.4491 43.8128 30.4589 43.3697 30.7382 43.1023L33.7713 40.1973C34.0506 39.9299 34.4937 39.9397 34.7611 40.219L38.6832 44.314C38.8155 44.4519 38.9982 44.5296 39.1886 44.5296H48.2494C48.6358 44.5296 48.9494 44.216 48.9494 43.8296V30.3693C48.9494 29.9829 48.6358 29.6693 48.2494 29.6693H39.1879C38.9968 29.6693 38.8141 29.747 38.6825 29.8849L34.7604 33.9806C34.493 34.2599 34.0499 34.2697 33.7706 34.0023L30.7375 31.0973C30.4582 30.8299 30.4484 30.3868 30.7158 30.1075L36.2906 24.2849C36.4229 24.147 36.6056 24.0693 36.796 24.0693H53.848C54.2344 24.0693 54.548 24.3829 54.548 24.7693V49.4296C54.5487 49.8167 54.2351 50.1296 53.8487 50.1296Z"
                          fill="#724362"/>
                    <path d="M13.8922 8.60227C13.608 8.33977 13.1649 8.35797 12.9031 8.64217L10.0548 11.7285C9.79228 12.0127 9.81048 12.4551 10.0947 12.7176L18.1657 20.1677C18.4499 20.4302 18.893 20.412 19.1548 20.1278L22.0031 17.0415C22.2656 16.7573 22.2474 16.3149 21.9632 16.0524L13.8922 8.60227Z"
                          fill="#F2637B"/>
                    <path d="M45.9481 12.0778L43.0998 8.9915C42.8373 8.7073 42.3949 8.6898 42.1107 8.9516L34.0397 16.4017C33.7555 16.6642 33.738 17.1066 33.9998 17.3908L36.8481 20.4771C37.1106 20.7613 37.553 20.7788 37.8372 20.517L45.9082 13.0669C46.1924 12.8051 46.2099 12.362 45.9481 12.0778Z"
                          fill="#F2637B"/>
                    <path d="M30.1012 3.74646H25.9012C25.5148 3.74646 25.2012 4.06006 25.2012 4.44646V15.216C25.2012 15.6024 25.5148 15.916 25.9012 15.916H30.1012C30.4876 15.916 30.8012 15.6024 30.8012 15.216V4.44646C30.8012 4.06006 30.4883 3.74646 30.1012 3.74646Z"
                          fill="#F2637B"/>
                </svg>
            </div>
            <h4>This will be special modal for Investments tasks</h4>
            <div className="d-flex gap-4 justify-content-center">
                <button onClick={() => {
                    removeCartItem(element, activeTasks, dispatch);
                    hideModal();
                }} className="btn btn-link">Ja, ta bort uppgift
                </button>
                <button onClick={hideModal} className="btn btn-link">Nej, avbryt</button>
            </div>
        </div>
    </Modal>);
    } else {
        return (<Modal size="sm"
                       aria-labelledby="contained-modal-title-vcenter"
                       centered show={isOpen} onHide={hideModal}
        >
            <div className="modal-header">
                <button onClick={hideModal} type="button" className="btn-close" data-bs-dismiss="modal"
                        aria-label="Close"></button>
            </div>
            <div className="modal-body p-6 text-center d-flex flex-column gap-4">
                <div>
                    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19.2037 50.1187H2.15312C1.76673 50.1187 1.45312 49.8051 1.45312 49.4187V24.7577C1.45312 24.3713 1.76673 24.0577 2.15312 24.0577H19.2065C19.3976 24.0577 19.5803 24.1361 19.7126 24.274L25.2881 30.1099C25.5555 30.3892 25.545 30.8323 25.2657 31.0997L22.2291 34.0005C21.9498 34.2672 21.5067 34.2574 21.2393 33.9781L17.3179 29.874C17.1856 29.7361 17.0029 29.6577 16.8118 29.6577H7.75312C7.36672 29.6577 7.05312 29.9713 7.05312 30.3577V43.8187C7.05312 44.2051 7.36672 44.5187 7.75312 44.5187H16.816C17.0064 44.5187 17.1891 44.441 17.3214 44.3031L21.2421 40.2165C21.5095 39.9372 21.9526 39.9281 22.2319 40.1962L25.2622 43.104C25.5408 43.3714 25.5506 43.8145 25.2825 44.0938L19.7084 49.9031C19.5768 50.041 19.3941 50.1187 19.2037 50.1187Z"
                              fill="#A0496D"/>
                        <path d="M53.8487 50.1296H36.7967C36.6056 50.1296 36.4229 50.0519 36.2913 49.914L30.7165 44.0921C30.4491 43.8128 30.4589 43.3697 30.7382 43.1023L33.7713 40.1973C34.0506 39.9299 34.4937 39.9397 34.7611 40.219L38.6832 44.314C38.8155 44.4519 38.9982 44.5296 39.1886 44.5296H48.2494C48.6358 44.5296 48.9494 44.216 48.9494 43.8296V30.3693C48.9494 29.9829 48.6358 29.6693 48.2494 29.6693H39.1879C38.9968 29.6693 38.8141 29.747 38.6825 29.8849L34.7604 33.9806C34.493 34.2599 34.0499 34.2697 33.7706 34.0023L30.7375 31.0973C30.4582 30.8299 30.4484 30.3868 30.7158 30.1075L36.2906 24.2849C36.4229 24.147 36.6056 24.0693 36.796 24.0693H53.848C54.2344 24.0693 54.548 24.3829 54.548 24.7693V49.4296C54.5487 49.8167 54.2351 50.1296 53.8487 50.1296Z"
                              fill="#724362"/>
                        <path d="M13.8922 8.60227C13.608 8.33977 13.1649 8.35797 12.9031 8.64217L10.0548 11.7285C9.79228 12.0127 9.81048 12.4551 10.0947 12.7176L18.1657 20.1677C18.4499 20.4302 18.893 20.412 19.1548 20.1278L22.0031 17.0415C22.2656 16.7573 22.2474 16.3149 21.9632 16.0524L13.8922 8.60227Z"
                              fill="#F2637B"/>
                        <path d="M45.9481 12.0778L43.0998 8.9915C42.8373 8.7073 42.3949 8.6898 42.1107 8.9516L34.0397 16.4017C33.7555 16.6642 33.738 17.1066 33.9998 17.3908L36.8481 20.4771C37.1106 20.7613 37.553 20.7788 37.8372 20.517L45.9082 13.0669C46.1924 12.8051 46.2099 12.362 45.9481 12.0778Z"
                              fill="#F2637B"/>
                        <path d="M30.1012 3.74646H25.9012C25.5148 3.74646 25.2012 4.06006 25.2012 4.44646V15.216C25.2012 15.6024 25.5148 15.916 25.9012 15.916H30.1012C30.4876 15.916 30.8012 15.6024 30.8012 15.216V4.44646C30.8012 4.06006 30.4883 3.74646 30.1012 3.74646Z"
                              fill="#F2637B"/>
                    </svg>
                </div>
                <h4>{translations[language].confirmationModalDelete}</h4>
                <div className="d-flex gap-4 justify-content-center">
                    <button onClick={() => {
                        removeCartItem(element, activeTasks, dispatch);
                        hideModal();
                    }} className="btn btn-link">{translations[language].confirmationModalDeleteyes}
                    </button>
                    <button onClick={hideModal} className="btn btn-link">{translations[language].confirmationModalDeleteno}</button>
                </div>
            </div>
        </Modal>);
    }

};

export default DeleteModal;