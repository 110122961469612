import React, {useState} from 'react';
import CompanyModal from './CompanyModal';


const Company = () => {
    const [isOpenCompanyModal, setIsOpenCompanyModal] = useState(false);

    const [activeData, setActiveData] = useState('iberdrola');


    const showCompanyModal = () => {
        if (!isOpenCompanyModal) {
            setIsOpenCompanyModal(true);
        }
    };
    const hideCompanyModal = () => {
        setIsOpenCompanyModal(false);
    };
    return (
            <section id={'company'}
                     className="block block--company"
                     data-spy={'#company'}>
                <div className="block__content">
                    <div className="container">
                        <div className="row justify-content-center text-center mt-lg-5 mb-6 mb-lg-9">
                            <div className="col-auto col-sm-10 col-lg-6 block__heading">
                                <h2 className="formue-animation">This is how a company drives impact</h2>
                                <p className="formue-animation lead mt-4" data-delay={0.1}>Most of the world’s companies
                                    have some negative impact. Here is an example of the most common driver, air
                                    pollution:</p>
                            </div>
                        </div>
                        <div className="row g-6 g-xl-9 text-center justify-content-center text-lg-start">
                            <div className="col-sm-8 col-lg-4 formue-animation">
                                <div className="d-flex flex-column gap-3 h-100 justify-content-between">
                                    <div className="d-flex flex-column gap-3">
                                        <div>
                                            <span className="step">1. Driver</span>
                                        </div>
                                        <h4>Air pollution</h4>
                                        <p className="m-0">GIST uses local wind speed and direction data to model where
                                            a factory’s air
                                            pollution
                                            build up.</p>
                                    </div>
                                    <div>
                                        <img className="mw-100 my-2" src={'../media/air-pollution-en.svg'}
                                             alt="air-pollution"/>
                                    </div>
                                    <div className="d-flex flex-column gap-2 text-center d-lg-none">
                                        <div>
                                            <img className="company-logo" src={'../media/cocacola.png'}
                                                 alt="Coca Cola Company"/>
                                        </div>
                                        <div className="p-3 s2 rounded">
                                            <p className="small m-0">Air pollution = 26 871 710,4 tCO2</p>
                                        </div>
                                        <div>
                                            <img className="company-logo" src={'../media/nestle.png'}
                                                 alt="Nestlé"/>
                                        </div>
                                        <div className="p-3 s2 rounded">
                                            <p className="small m-0">Air pollution = 26 871 710,4 tCO2</p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="col-sm-8 col-lg-4 formue-animation" data-delay={0.1}>
                                <div className="d-flex flex-column gap-3 h-100 justify-content-between">
                                    <div className="d-flex flex-column gap-3">
                                        <div>
                                            <span className="step">2. Outcome</span>
                                        </div>
                                        <h4>Pollutants breathed in</h4>
                                        <p className="m-0">GIST calculates esposure based on density and demographic of
                                            nearby
                                            populations.</p>
                                    </div>
                                    <div>
                                        <img className="mw-100 my-2" src={'../media/pollutants-breathed.svg'}
                                             alt="pollutants-breathed"/>
                                    </div>
                                    <div className="d-flex flex-column gap-2 text-center d-lg-none">
                                        <div>
                                            <img className="company-logo" src={'../media/cocacola.png'}
                                                 alt="Coca Cola Company"/>
                                        </div>
                                        <div className="p-3 s2 rounded">
                                            <p className="small m-0">Some facts about the exposure (number of
                                                people/cities affected?)</p>

                                        </div>
                                        <div>
                                            <img className="company-logo" src={'../media/nestle.png'}
                                                 alt="Nestlé"/>
                                        </div>
                                        <div className="p-3 s2 rounded">
                                            <p className="small m-0">Some facts about the exposure (number of
                                                people/cities affected?)</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-8 col-lg-4 formue-animation" data-delay={0.2}>
                                <div className="d-flex flex-column gap-3 h-100 justify-content-between ">
                                    <div className="d-flex flex-column gap-3">
                                        <div>
                                            <span className="step">3. Impact</span>
                                        </div>
                                        <h4>Societal cost of illness</h4>
                                        <p className="m-0">GIST calculates health impacts and costs to individuals and
                                            society as a
                                            result
                                            of exposure to pollutants.</p>
                                    </div>
                                    <div>
                                        <img className="mw-100 my-2" src={'../media/societal-cost.svg'} alt="societal"/>
                                    </div>
                                    <div className="d-flex flex-column gap-2 text-center d-lg-none">
                                        <div>
                                            <img className="company-logo" src={'../media/cocacola.png'}
                                                 alt="Coca Cola Company"/>
                                        </div>
                                        <div className="p-3 s2 rounded">
                                            <p className="small m-0">Negative impact = -48 685 235 NOK</p>
                                        </div>
                                        <div>
                                            <img className="company-logo" src={'../media/nestle.png'}
                                                 alt="Nestlé"/>
                                        </div>
                                        <div className="p-3 s2 rounded">
                                            <p className="small m-0">Negative impact = -48 685 235 NOK</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="d-none d-lg-flex gap-6 flex-column mt-7">

                            <div className="p-3 rounded border formue-animation z-1 position-relative" data-delay={0.3}>
                                <div>
                                    <img className="company-logo s1" src={'../media/Iberdrola_wordmark 2.jpg'} style={{maxWidth:120,height:"auto"}}
                                         alt="Iberdrola Company"/>
                                </div>
                                <div className="row g-6 g-xl-9 justify-content-center align-items-center">
                                    <div className="col-sm-8 col-lg-4">
                                        <div className="p-3 s2 rounded">
                                            <p className="small m-0">Air pollution = 26 871 710,4 tCO2</p>
                                        </div>
                                    </div>
                                    <div className="col-sm-8 col-lg-4">
                                        <div className="p-3 s2 rounded d-flex align-items-center">
                                            <p className="small m-0">Some facts about the exposure (number of
                                                people/cities affected?)</p>
                                            <button className="btn" onClick={() => {
                                                setActiveData('iberdrola');
                                                showCompanyModal();
                                            }}>Details
                                            </button>
                                        </div>
                                    </div>
                                    <div className="col-sm-8 col-lg-4">
                                        <div className="p-3 s2 rounded d-flex align-items-center">
                                            <p className="small m-0">Negative impact = -48 685 235 NOK</p>
                                            <button className="btn" onClick={() => {
                                                setActiveData('iberdrola2');
                                                showCompanyModal();
                                            }}>Details
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="p-3 rounded border formue-animation" data-delay={0.4}>
                                <div>
                                    <img className="company-logo s1 mt-0" src={'../media/yara-seeklogo.com 2.jpg'} style={{height:'auto'}}
                                         alt="yara"/>
                                </div>
                                <div className="row g-6 g-xl-9 justify-content-center align-items-center">
                                    <div className="col-sm-8 col-lg-4">
                                        <div className="p-3 s2 rounded">
                                            <p className="small m-0">Air pollution = 26 871 710,4 tCO2</p>
                                        </div>
                                    </div>
                                    <div className="col-sm-8 col-lg-4">
                                        <div className="p-3 s2 rounded d-flex align-items-center">
                                            <p className="small m-0">Some facts about the exposure (number of
                                                people/cities affected?)</p>
                                            <button className="btn" onClick={() => {
                                                setActiveData('yara');
                                                showCompanyModal();
                                            }}>Details
                                            </button>
                                        </div>
                                    </div>
                                    <div className="col-sm-8 col-lg-4">
                                        <div className="p-3 s2 rounded d-flex align-items-center">
                                            <p className="small m-0">Negative impact = -48 685 235 NOK</p>
                                            <button className="btn" onClick={() => {
                                                setActiveData('yara2');
                                                showCompanyModal();
                                            }}>Details
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <CompanyModal isOpenCompanyModal={isOpenCompanyModal} hideCompanyModal={hideCompanyModal}
                              activeData={activeData}/>
            </section>

    );
};

export default Company;
