import React, {useEffect, useRef, useState} from 'react';
import {SmoothCorners} from 'react-smooth-corners';

const BetaBar = (props) => {

    // const el = useRef(null);
    // const [lockIcon, setLockIcon] = useState(false)
    // useEffect(() => {
    //     if (!lockIcon) {
    //         window.bodymovin.loadAnimation({
    //             wrapper: el.current,
    //             animType: 'svg',
    //             loop: true,
    //             path: '../media/alertCircle.json'
    //         });
    //         setLockIcon(true)
    //     }
    // }, [lockIcon]);

    return (
            <div className="beta-bar">
                <div className="container">
                    <div className="inner">
                        {/*<span className="alert-icon" ref={el}></span>*/}
                        <div className="tool-label">
                            <span className="tool-label__title">{props.name}</span>
                            <SmoothCorners className={'d-inline-flex'}>
                                <span className="badge">Beta</span>
                            </SmoothCorners>
                        </div>
                    </div>
                </div>
            </div>
    );
};
export default BetaBar;
