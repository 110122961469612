import React from 'react';
import {ProgressBar} from 'react-bootstrap';
import {langSeparator} from '../../hooks/helperFunctions';

const VerticalLifestyleLine = (props) => {
    const {name, value, maxVal, type, language} = props;

    let opacity = 0.3;
    if (maxVal === value) {
        opacity = 1;
    }
    let finalVal;
    if (value === maxVal) {
        finalVal = 100;
    } else {
        finalVal = (value * 100) / maxVal;
    }
    return (
            // <div className="text-center d-flex flex-column">
            //     <div className="d-flex align-items-end" style={{height: 130}}>
            //         <div className="line mx-auto mb-0" style={{height: finalVal + '%', opacity: opacity}}>
            //         </div>
            //     </div>
            //     <p className="percent mt-2 m-0">{value.toFixed(1)}</p>
            //     <p className={type + " small mb-0"}>{name}</p>
            //
            // </div>
            <div className={value === maxVal ? 'col d-flex flex-column active ' : 'col d-flex flex-column'}
                 style={{flex: '1 1'}}>
                <div className="d-flex align-items-end justify-content-center" style={{minHeight: 200, maxHeight: 200}}>
                    <div className="text-center">
                        <div className="mb-2">
                            <p className="m-0 font-weight-medium">
                                 <small className={value === maxVal ? 'font-weight-medium d-block' : 'd-block'}>
                                     +{value.toLocaleString(langSeparator(language),{maximumFractionDigits:1})}
                                     {/* <span className="small">tCO<sub>2</sub></span> */}
                    </small>

                            </p>
                        </div>
                        <ProgressBar style={{height: finalVal * 1.5}}
                                     className={value === maxVal ? 'active progress-bar-vertical mx-auto' : 'progress-bar-vertical mx-auto'}
                                     now={'100%'}/>
                    </div>
                </div>
                <p className="m-0 px-1 mt-2">
                    <small className={value === maxVal && 'font-weight-medium'}>{name}</small>
                </p>
            </div>);
};
export default VerticalLifestyleLine;