import React, {useContext} from 'react';
import {Settings} from '../../store';
import translations from '../../data/footprint/lang.json';
import Form from 'react-bootstrap/Form';

const SummaryNegativeImpactRegular = (props) => {
    const settingsState = useContext(Settings.State);
    const dispatch = useContext(Settings.Dispatch);
    const checkboxItems = props.checkboxItems || settingsState.checkboxItems;
    const invalidValidation = props.invalidValidation;
    const {
        language,
        negativeImpact,
    } = settingsState;

    const handleCheckboxItems = (item) => {
        if (checkboxItems.includes(item)) {
            dispatch({type: 'setCheckboxItems', payload: checkboxItems.filter((i) => i !== item)});
        } else {
            dispatch({type: 'setCheckboxItems', payload: [...checkboxItems, item]});
        }
    };

    return (
            <>
                <div className="col-sm-6">
                    <h5 className="mb-3">{translations[language].investments_content.conclusion.smallheading1}</h5>
                    <div className="d-flex flex-column gap-1">
                        <div className="d-flex align-items-center">
                            <Form.Check
                                    isInvalid={!checkboxItems.length && negativeImpact.includes('B.') && invalidValidation}
                                    checked={checkboxItems.includes(translations[language].investments_content.conclusion.list1)}
                                    id={`ddefault-checkbox`}
                                    type={'checkbox'}
                                    className="small"
                                    label={translations[language].investments_content.conclusion.list1}
                                    onClick={() => handleCheckboxItems(translations[language].investments_content.conclusion.list1)}

                            />

                        </div>
                        <div className="d-flex align-items-center">
                            <Form.Check
                                    isInvalid={!checkboxItems.length && negativeImpact.includes('B.') && invalidValidation}
                                    checked={checkboxItems.includes(translations[language].investments_content.conclusion.list2)}
                                    id={`ddefault-checkbox2`}
                                    type={'checkbox'}
                                    className="small"
                                    label={translations[language].investments_content.conclusion.list2}
                                    onClick={() => handleCheckboxItems(translations[language].investments_content.conclusion.list2)}

                            />
                        </div>
                        <div className="d-flex align-items-center">
                            <Form.Check
                                    isInvalid={!checkboxItems.length && negativeImpact.includes('B.') && invalidValidation}
                                    checked={checkboxItems.includes(translations[language].investments_content.conclusion.list3)}
                                    id={`ddefault-checkbox3`}
                                    type={'checkbox'}
                                    className="small"
                                    label={translations[language].investments_content.conclusion.list3}
                                    onClick={() => handleCheckboxItems(translations[language].investments_content.conclusion.list3)}

                            />
                        </div>
                        <div className="d-flex align-items-center">
                            <Form.Check
                                    isInvalid={!checkboxItems.length && negativeImpact.includes('B.') && invalidValidation}
                                    checked={checkboxItems.includes(translations[language].investments_content.conclusion.list4)}
                                    id={`ddefault-checkbox4`}
                                    type={'checkbox'}
                                    className="small"
                                    label={translations[language].investments_content.conclusion.list4}
                                    onClick={() => handleCheckboxItems(translations[language].investments_content.conclusion.list4)}

                            /></div>
                        <div className="d-flex align-items-center">
                            <Form.Check
                                    isInvalid={!checkboxItems.length && negativeImpact.includes('B.') && invalidValidation}
                                    checked={checkboxItems.includes(translations[language].investments_content.conclusion.list5)}
                                    id={`ddefault-checkbox5`}
                                    type={'checkbox'}
                                    className="small"
                                    label={translations[language].investments_content.conclusion.list5}
                                    onClick={() => handleCheckboxItems(translations[language].investments_content.conclusion.list5)}

                            />

                        </div>
                    </div>
                </div>
                <div className="col-sm-6">
                    <h5 className="mb-3">{translations[language].investments_content.conclusion.smallheading2}</h5>
                    <div className="d-flex flex-column gap-1">
                        <div className="d-flex align-items-center">
                            <Form.Check
                                    isInvalid={!checkboxItems.length && negativeImpact.includes('B.') && invalidValidation}
                                    checked={checkboxItems.includes(translations[language].investments_content.conclusion.list6)}
                                    id={`ddefault-checkbox6`}
                                    type={'checkbox'}
                                    className="small"
                                    label={translations[language].investments_content.conclusion.list6}
                                    onClick={() => handleCheckboxItems(translations[language].investments_content.conclusion.list6)}

                            />

                        </div>
                        <div className="d-flex align-items-center">
                            <Form.Check
                                    isInvalid={!checkboxItems.length && negativeImpact.includes('B.') && invalidValidation}
                                    checked={checkboxItems.includes(translations[language].investments_content.conclusion.list7)}
                                    id={`ddefault-checkbox7`}
                                    type={'checkbox'}
                                    className="small"
                                    label={translations[language].investments_content.conclusion.list7}
                                    onClick={() => handleCheckboxItems(translations[language].investments_content.conclusion.list7)}
                            />

                        </div>
                        <div className="d-flex align-items-center">
                            <Form.Check
                                    isInvalid={!checkboxItems.length && negativeImpact.includes('B.') && invalidValidation}
                                    checked={checkboxItems.includes(translations[language].investments_content.conclusion.list8)}
                                    id={`ddefault-checkbox8`}
                                    type={'checkbox'}
                                    className="small"
                                    label={translations[language].investments_content.conclusion.list8}
                                    onClick={() => handleCheckboxItems(translations[language].investments_content.conclusion.list8)}
                            /></div>
                    </div>
                </div>
            </>
    );
};

export default SummaryNegativeImpactRegular;