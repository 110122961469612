import React, {useContext, useEffect} from 'react';
import Modal from 'react-bootstrap/Modal';
import TagManager from 'react-gtm-module';
import translations from '../../../data/footprint/lang.json';
import {Settings} from '../../../store';

const HowWeEstimateModal = (props) => {
    const settingsState = useContext(Settings.State);

    const {
        language,
    } = settingsState;
    const {
        isHowWeEstimateOpen, hideHowWeEstimateModal,
    } = props;

    return (<Modal size="md"
                   aria-labelledby="contained-modal-title-vcenter"
                   centered show={isHowWeEstimateOpen} onHide={hideHowWeEstimateModal}
    >
        <div className="modal-header">
            <button onClick={hideHowWeEstimateModal} type="button" className="btn-close" data-bs-dismiss="modal"
                    aria-label="Close"></button>
        </div>
        <div className="modal-body p-lg-7">
            <p><strong>{translations[language].lifestyle_inputs.howwestimate}:</strong></p>
            <div
                    dangerouslySetInnerHTML={{__html: translations[language].lifestyle_inputs.popup}}></div>
            <hr/>
            <p><strong>{translations[language].investments_inputs.howwestimate}:</strong></p>
            <div
                    dangerouslySetInnerHTML={{__html: translations[language].investments_inputs.popup}}></div>
            <hr/>
            <p><strong>{translations[language].businessblock.question}:</strong></p>
            <div
                    dangerouslySetInnerHTML={{__html: translations[language].businessblock.popup}}></div>
        </div>
    </Modal>);
};

export default HowWeEstimateModal;