import React, {useEffect, useRef, useState} from 'react';
import SwiperBack from './SwiperBack';
import SwiperNavigation from './SwiperNavigation';
import SwiperCart from './SwiperCart';
import Headroom from 'react-headroom';

const SwiperHeader = (props) => {
    const {swiper, activeSlide} = props;
    const el = useRef(null);

    useEffect(() => {
        const handleScroll = (element) => {
            if (document.getElementById(activeSlide).scrollTop > 100) {
                el.current.classList.add('headroom--unpinned');
            } else {
                el.current.classList.remove('headroom--unpinned');
            }
        };
        window.addEventListener('scroll', handleScroll);

        const elements = document.querySelectorAll('.swiper-slide');
        for (let i = 0; i < elements.length; i++) {
            elements[i].addEventListener('scroll', function() {
                handleScroll(elements[i]);
            });
        }
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [activeSlide]);

    useEffect(() => {
        if (document.getElementById(activeSlide).scrollTop > 100) {
            el.current.classList.add('headroom--unpinned');
        } else {
            el.current.classList.remove('headroom--unpinned');
        }
    }, [activeSlide]);


    return (activeSlide && <div
                    className="header position-absolute start-0 end-0 top-0 z-3">
                <div className="container-fluid px-3 py-0 p-md-5">
                    <div ref={el}
                         className="headroom swiper-navigation d-flex justify-content-md-between align-items-center gap-2">
                        <SwiperBack swiper={swiper}/>
                        <SwiperNavigation swiper={swiper}/>
                    </div>
                </div>
            </div>
    );
};

export default SwiperHeader;