import React, {useContext, useState, useEffect} from 'react';
import {Settings} from '../../../store';
import OffcanvasMenu from '../OffcanvasMenu';
import translations from '../../../data/footprint/lang.json';

const SwiperCart = (props) => {
    const [show, setShow] = useState(false);

    const {activeTasks} = useContext(Settings.State);
    const [counter, setCounter] = useState(0);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        setCounter(activeTasks.length);
    }, [activeTasks.length]);

    return (<>
                <div id="swiper-cart-wrapper" className="position-fixed top-0 end-0 zIndex-1">

                    <button type="button" id="swiper-cart" onClick={handleShow}
                            className={counter === 0 ? ' btn btn--cart art--icon d-flex justify-content-center align-items-center  gap-1' : ' btn btn--cart cart--icon gap-1 d-flex justify-content-center align-items-center'}>
                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.2972 21.761L8.15035 15.6138C7.99395 15.4574 7.99435 15.2042 8.15035 15.0482L9.84715 13.3514C10.0036 13.195 10.2568 13.195 10.4128 13.3514L14.2972 17.2354C14.4536 17.3914 14.7068 17.3914 14.8628 17.2354L21.366 10.7326C21.5224 10.5762 21.7756 10.5766 21.9316 10.7326L23.6284 12.4294C23.7848 12.5858 23.7848 12.839 23.6284 12.995L14.8628 21.761C14.7068 21.9174 14.4536 21.9174 14.2972 21.761Z"
                                  fill="#45A2FF"/>
                            <path d="M8.5517 28.3155C8.4353 28.5071 8.1813 28.5695 7.9929 28.4483C3.4193 25.5063 0.838496 20.3947 1.2425 14.8719C1.7373 8.11229 6.8965 2.48389 13.5865 1.39629C22.1629 0.00149144 29.8733 6.21309 30.7233 14.4727C30.7461 14.6959 30.5757 14.8943 30.3521 14.9111L27.9577 15.0903C27.7413 15.1067 27.5561 14.9459 27.5325 14.7299C26.8505 8.47629 21.1461 3.74189 14.6805 4.47469C8.9093 5.12909 4.4501 10.0923 4.4021 15.9003C4.3689 19.9011 6.3353 23.5487 9.6677 25.7199C9.8497 25.8387 9.9089 26.0763 9.7965 26.2623L8.5517 28.3155Z"
                                  fill="#4C68A0"/>
                            <path d="M13.4654 30.5835C12.4974 30.4163 11.5486 30.1527 10.6346 29.7971C10.4254 29.7159 10.3274 29.4739 10.4146 29.2671L11.3474 27.0543C11.4318 26.8543 11.6578 26.7631 11.8606 26.8403C12.5334 27.0971 13.2306 27.2907 13.9414 27.4179C14.155 27.4563 14.3018 27.6515 14.2706 27.8659L13.9278 30.2427C13.8954 30.4651 13.6862 30.6219 13.4654 30.5835Z"
                                  fill="#464F81"/>
                            <path d="M16 30.3917V27.9901C16 27.7729 16.1736 27.6005 16.3904 27.5933C17.0876 27.5701 17.7796 27.4853 18.4556 27.3397C18.668 27.2941 18.8776 27.4189 18.9308 27.6297L19.516 29.9589C19.5708 30.1765 19.4368 30.4005 19.218 30.4489C18.2984 30.6529 17.3568 30.7685 16.4084 30.7945C16.1844 30.8005 16 30.6161 16 30.3917Z"
                                  fill="#464F81"/>
                            <path d="M21.6629 29.2344L20.7177 27.0284C20.6321 26.8284 20.7237 26.6008 20.9209 26.5084C21.7541 26.1176 22.5373 25.6292 23.2569 25.0508C23.4265 24.9144 23.6713 24.9324 23.8133 25.0972L25.3777 26.9176C25.5241 27.0876 25.5045 27.3484 25.3301 27.49C24.3689 28.2708 23.3185 28.926 22.1973 29.4436C21.9941 29.538 21.7513 29.4404 21.6629 29.2344Z"
                                  fill="#464F81"/>
                            <path d="M26.919 25.3772L25.0982 23.812C24.9334 23.6704 24.9158 23.4256 25.0518 23.256C26.4418 21.5264 27.2934 19.4712 27.5314 17.2708C27.5546 17.0548 27.7402 16.894 27.957 16.91L30.3514 17.0888C30.5746 17.1056 30.745 17.3032 30.7222 17.526C30.4286 20.4012 29.3178 23.0836 27.4898 25.33C27.349 25.5036 27.089 25.5232 26.919 25.3772Z"
                                  fill="#464F81"/>
                        </svg>


                        <span className={counter === 0 ? 'position-absolute badge rounded-pill btn--cart__no d-none' : 'position-absolute badge rounded-pill btn--cart__no'}>{counter}
                            <span className="visually-hidden">Items in cart</span>
                    </span>
                        {/* <span className={counter === 0 ? 'd-none' : 'font-weight-medium'}>{counter}</span> */}
                    </button>

                </div>

                <OffcanvasMenu showContactModal={props.showContactModal} handleClose={handleClose} show={show}/>
            </>
    );
};

export default SwiperCart;