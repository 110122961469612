import React, {useEffect, useState} from 'react';
import {gsap} from 'gsap';
import {Settings} from '../store';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import translations from '../data/impact/lang.json';
import Banner from './impact/hero/Banner';
import LoadingScreen from './general/LoadingScreen';
import Header from './impact/Header';
import {hasClass, langSeparator} from '../hooks/helperFunctions';
import BetaBar from './general/BetaBar';
import Widget from './impact/widgets/Widget';
import Main from './impact/Main';

const Impact = (props) => {
    document.title = 'Impact tool';
    document.body.id = 'impact-app';
    gsap.registerPlugin(ScrollTrigger);
    const {appName} = props;
    const settingsState = React.useContext(Settings.State);
    const {language} = settingsState;
    const [isLoaded, setIsLoaded] = useState(null);
    const [isMainActive, setIsMainActive] = useState(null);
    const [investmentValue, setInvestmentValue] = useState(0);
    const [negativeCalculation, setNegativeCalculation] = useState((investmentValue / 1000000) * 135261);
    const [negativeImpactAverageFiveYears, setNegativeImpactAverageFiveYears] = useState('');
    const [hideMain, setHideMain] = useState(true);
    const [isContactOpen, setIsContactOpen] = useState(false);
    const [isCalendlyOpen, setIsCalendlyOpen] = useState(false);

    const showContactModal = () => {
        setIsContactOpen(true);
    };
    const hideContactModal = () => {
        setIsContactOpen(false);
    };
    const showCalendlyModal = () => {
        setIsCalendlyOpen(true);
    };
    const hideCalendlyModal = () => {
        setIsCalendlyOpen(false);
    };
    const updateInvestmentValue = e => {
        if (e.floatValue == undefined) {
            setInvestmentValue(0);
        } else {
            setInvestmentValue(e.floatValue);
        }
    };

    useEffect(() => {
        setNegativeCalculation((investmentValue / 1000000) * 135261);
    }, [investmentValue]);

    useEffect(() => {
        setNegativeImpactAverageFiveYears((8677058608403 * translations[language].general.currencyvalue / 1000000000).toLocaleString(langSeparator(language), {maximumFractionDigits: 0}) + ' ' + translations[language].general.billions + ' ' + translations[language].general.currency);
    }, [language]);

    useEffect(() => {

        if (isLoaded) {
            //needs to be 1200ms because of Loader screen
            setTimeout(() => {
                document.body.classList.remove('is-loading');
                document.body.classList.add('loaded');
            }, 1200);
            document.body.classList.add('lang-' + language);
        } else {
            document.body.classList = 'is-loading';
        }
    }, [isLoaded]);


    useEffect(() => {
        gsap.utils.toArray('.formue-animation').forEach(function(e) {
            let delay = 0;
            let duration = 0.4;
            if (e.getAttribute('data-delay') !== null) {
                delay = e.getAttribute('data-delay');
            }

            if (e.getAttribute('data-duration') !== null) {
                duration = e.getAttribute('data-duration');
            }
            gsap.from(e, {
                duration: duration,
                opacity: 0,
                y: 16,
                delay: delay,
                scrollTrigger: {
                    trigger: e,
                    start: 'top 60%',
                },
            });
        });

    }, [hideMain]);


    useEffect(() => {
        gsap.utils.toArray('section').forEach(function(e) {
            if (hasClass(e, 'block')) {
                gsap.to(e, {
                    scrollTrigger: {
                        trigger: e,
                        start: 'top 5px',
                        end: 'bottom',
                        toggleClass: 'in-viewport',
                    },
                });
            }
            let theme = e.getAttribute('data-section-theme');
            if (e.getAttribute('data-section-theme') == null) {
                theme = 'default';
            }
            gsap.timeline({
                scrollTrigger: {
                    trigger: e,
                    start: 'top 50%',
                    end: 'bottom 50%',
                    onEnterBack: () =>
                            gsap.set('body', {attr: {['data-theme']: theme}}),
                    onEnter: () =>
                            gsap.set('body', {attr: {['data-theme']: theme}}),
                },
            });

        });
    }, [isMainActive]);


    return (<>
        <LoadingScreen setIsLoaded={setIsLoaded}/>
        <div style={{visibility: isLoaded ? 'visible' : 'hidden'}} className="outer-wrapper">
            <BetaBar name={'Impact tool'}/>
            <Header appName={appName}/>
            <Banner hideMain={hideMain} setHideMain={setHideMain} setInvestmentValue={setInvestmentValue}
                    investmentValue={investmentValue} isLoaded={isLoaded}
                    updateInvestmentValue={updateInvestmentValue}/>
            {!hideMain && <Main setIsMainActive={setIsMainActive} hideMain={hideMain}
                                negativeImpactAverageFiveYears={negativeImpactAverageFiveYears}
                                negativeCalculation={negativeCalculation}
                                investmentValue={investmentValue}
                                isLoaded={isLoaded}
                                showContactModal={showContactModal}/>}

            <Widget hideCalendlyModal={hideCalendlyModal} showCalendlyModal={showCalendlyModal}
                    isCalendlyOpen={isCalendlyOpen} showContactModal={showContactModal}
                    hideContactModal={hideContactModal}
                    isContactOpen={isContactOpen}
            />
        </div>
    </>);
};

export default Impact;
