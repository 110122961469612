import React, {useEffect, useRef} from 'react';
import InvestingReturn from './investing/InvestingReturn';
import Sectors from './sector/Sectors';
import Continents from './continents/Continents';
import SDGs from './sdgs/SDGs';
import TakeAction from './takeAction/TakeAction';
import ScrollspyNav from './widgets/Scrollspy';
import Footer from '../general/Footer';
import {Settings} from '../../store';
import {gsap} from 'gsap';
import {langSeparator} from '../../hooks/helperFunctions';
import Company from './company/Company';
import Impact from './impact/index';
import translations from '../../data/impact/lang.json';

const Main = (props) => {

    const {
        negativeImpactAverageFiveYears,
        negativeCalculation,
        investmentValue,
        isLoaded,
        showContactModal,
        hideMain,
        setIsMainActive,
        isMainActive,
    } = props;
    const settingsState = React.useContext(Settings.State);
    const {language} = settingsState;
    const el = useRef(null);


    useEffect(() => {
        if (!hideMain) {
            gsap.set('main', {
                visibility: 'visible', height: 'auto', overflow: 'initial',
            });
            gsap.to(window, 0, {
                scrollTo: '#main',
                ease: 'none',
                duration: 1,
                delay: 0,
                onComplete: () => {
                    const el = document.querySelectorAll('body, html, .outer-wrapper');
                    el.forEach(box => {
                        box.classList.remove('no-scroll');
                    });
                },
            });
        }
    }, [hideMain]);

    useEffect(() => {
        setTimeout(() => {
            setIsMainActive(true);
        }, 500);
    }, []);


    return (<main ref={el} id="main" className="main">
        <InvestingReturn negativeImpactAverageFiveYears={negativeImpactAverageFiveYears} negativeCalculation={negativeCalculation}
                         investmentValue={investmentValue}/>
        <section className="sectors block">
            <header id="section-header" className="section-header">
                <div className="section-header__inner">
                    <h6>
                        <span>Your Share:</span> - {negativeCalculation.toLocaleString(langSeparator(language), {maximumFractionDigits: 0})} {translations[language].general.currency}
                    </h6>
                </div>
            </header>
            <Company/>
            <Impact negativeCalculation={negativeCalculation} isMainActive={isMainActive}
                    negativeImpactAverageFiveYears={negativeImpactAverageFiveYears}/>
            <div id="data-block">
                <Sectors negativeCalculation={negativeCalculation} investmentValue={investmentValue}/>
                <Continents investmentValue={investmentValue} negativeCalculation={negativeCalculation}/>
                <SDGs negativeCalculation={negativeCalculation} investmentValue={investmentValue}/>
            </div>
        </section>
        <TakeAction negativeCalculation={negativeCalculation} investmentValue={investmentValue}/>
        <ScrollspyNav isLoaded={isLoaded}/>
        <Footer showContactModal={showContactModal}/>
    </main>);
};

export default Main;
