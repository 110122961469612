import React, {useRef, useEffect} from 'react';
import BFData from '../../data/footprint/businessFootprint.json';
import {Settings} from '../../store';
import translations from '../../data/footprint/lang.json';

const BusinessSearchBlock = () => {
    const el = useRef(null);
    const settingsState = React.useContext(Settings.State);
    const dispatch = React.useContext(Settings.Dispatch);
    const {language} = settingsState;

    const findSearchedValues = (e) => {
        if (e.target.value === '') {
            dispatch({
                type: 'setSearchQuery',
                payload: '',
            });
            return;
        }
        const filtered = BFData[language].searchableValues.filter((entry) => {
            let subindustry = entry.sub_industry;
            return subindustry.toLowerCase().includes((e.target.value).toLowerCase());
        });
        dispatch({
            type: 'setSearchQuery',
            payload: filtered,
        });
    };

    useEffect(() => {
        el.current.focus({
            behavior: 'none',
            preventScroll: true,
        });

    }, []);

    return (
            <>
                <div className="w-100" style={{height: 100}}>
                    <div className="mb-4 p-3 position-absolute w-100 zIndex-3">
                        {React.createElement('input', {
                            placeholder: translations[language].general.search,
                            onChange: findSearchedValues,
                            className: 'w-100 mb-2 business-dropdown--item border-0',
                            id: 'search-input',
                            defaultValue: '',
                            type: 'search',
                            ref: el,
                            style: {cursor: 'auto', backgroundImage: 'none'},
                        })}

                    </div>
                </div>
            </>
    );
};

export default BusinessSearchBlock;
