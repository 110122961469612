import React from 'react';
import {SmoothCorners} from 'react-smooth-corners';
import sdgData from '../../../data/impact/sdg-data.json';
import {langSeparator} from '../../../hooks/helperFunctions';
import {Settings} from '../../../store';
import translations from '../../../data/impact/lang.json';

const SDG = (props) => {
    const {
        item,
        setActiveSDG,
        showModal,
        totalSDGImpactValue,
        grid,
        order,
        setActiveSDGCard,
        negativeCalculation,
    } = props;
    const settingsState = React.useContext(Settings.State);
    const {language} = settingsState;
    const id = sdgData[item].id;
    const color = sdgData[item].color;
    const name = sdgData[item].name;
    const percent = (sdgData[item].totalSectorImpact * 100) / totalSDGImpactValue;

    return (
            <div onClick={() => {
                setActiveSDGCard(id);
                setActiveSDG(sdgData['SDG' + id]);
                setTimeout(() => {
                    showModal();
                }, 200);
            }}
                 data-sdg={id}
                 className={'card grid__item order-' + order}
                 style={{gridArea: grid, '--medium': 'auto'}}>
                <span className="label d-none">- {((negativeCalculation * percent) / 100).toLocaleString(langSeparator(language), {maximumFractionDigits: 0})} {translations[language].general.currency}</span>
                <div className={'grid__item__inner'} style={{background: color}}>
                    <div className="overlay-fill" style={{width: percent + '%'}}></div>
                    <div className="d-block d-lg-none">
                        <h5>{id}. {name}</h5>
                    </div>
                    <div className="h-100 d-none d-lg-block">
                        <img className="opacity-100" src={'../media/SDGs/' + id + '.svg'} alt=""/>
                    </div>
                    <span className="icon"><svg xmlns="http://www.w3.org/2000/svg" height="24" width="24"><path
                            d="M7.15 21.1q-.375-.375-.375-.888 0-.512.375-.887L14.475 12l-7.35-7.35q-.35-.35-.35-.875t.375-.9q.375-.375.888-.375.512 0 .887.375l8.4 8.425q.15.15.213.325.062.175.062.375t-.062.375q-.063.175-.213.325L8.9 21.125q-.35.35-.862.35-.513 0-.888-.375Z"/></svg></span>
                </div>
            </div>
    );
};

export default SDG;
