import translations from '../../../data/impact/lang.json';
import React, {useRef, useState,useEffect} from 'react';
import {gsap} from 'gsap';
import {langSeparator} from '../../../hooks/helperFunctions';
import {Settings} from '../../../store';
import sdgData from '../../../data/impact/sdg-data.json';
import sectorCodes from '../../../data/impact/sector-codes.json'

const SDGSectorItem = (props) => {
    const {
        item,
        activeSDG,
        totalSDGImpactValue,
        showModalDetails,
        setActiveSDGSector,
        index,
        grid,
        negativeCalculation,
        setRemainingSectorsCalc,
    } = props;
    const [activeItem, setActiveItem] = useState(false);
    const settingsState = React.useContext(Settings.State);
    const {language} = settingsState;
    const totalImpactIN2020 = item.totalNegativeImpact;
    const totalImpactIn2020Procent = (totalImpactIN2020 * 100) / activeSDG.totalSectorImpact;
    const el = useRef(null);

    const handleSectorClick = (e) => {
        e.currentTarget.classList.add('active');
        document.body.classList.toggle('selected');
        setActiveSDGSector(item);
        showModalDetails();
    };

    const percent = ((sdgData['SDG' + activeSDG.id].totalSectorImpact * 100) / totalSDGImpactValue);
    const activeSDGImpact = (negativeCalculation * percent) / 100;
    const finalCalculation = (activeSDGImpact * totalImpactIn2020Procent) / 100;


    useEffect(() => {
        setRemainingSectorsCalc(prev => prev + finalCalculation);
    }, []);


    return (<div style={{gridArea: grid}} ref={el} onClick={handleSectorClick} className={'sectors__item'}
    >
        <div className="content">
            <div className="overlay-fill" style={{width: totalImpactIn2020Procent + '%'}}></div>
            <div className="base-info">
                <span className="label">{sectorCodes[item.name].name}</span>
                <h5 className="d-none d-lg-block text-right"> - {finalCalculation.toLocaleString(langSeparator(language), {maximumFractionDigits: 0})} {translations[language].general.currency}
                </h5>
            </div>
        </div>
    </div>);
};

export default SDGSectorItem;
