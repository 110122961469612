import React, {useContext, useEffect, useRef, useState} from 'react';
import {gsap} from 'gsap';
import {Settings} from '../store';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import Banner from './footprint/hero/Banner';
import LoadingScreen from './general/LoadingScreen';
import Header from './footprint/Header';
import SwiperGroup from './footprint/swiper/SwiperGroup';
import Modal from 'react-bootstrap/Modal';
import BetaBar from './general/BetaBar';
import Footer from './general/Footer';
import FootprintFooter from './footprint/FootprintFooter';

const Footprint = (props) => {
    gsap.registerPlugin(ScrollTrigger);
    const {appName} = props;
    const settingsState = React.useContext(Settings.State);
    const {language, portfolioValue, activeScreen} = settingsState;
    const [isLoaded, setIsLoaded] = useState(null);
    const [screenTwoLoaded, setScreenTwoLoaded] = useState(false);
    const [screenThirdLoaded, setScreenThirdLoaded] = useState(false);
    document.title = 'Footprint tool';
    document.body.id = 'footprint-app';
    useEffect(() => {
        if (isLoaded) {
            //needs to be 1200ms because of Loader screen
            setTimeout(() => {
                document.body.classList.remove('is-loading');
                document.body.classList.add('loaded');
            }, 1200);
            document.body.classList.add('lang-' + language);
        }
    }, [isLoaded]);


    useEffect(() => {

        gsap.utils.toArray('.formue-animation').forEach(function(e) {
            let delay = 0;
            let duration = 0.4;
            let offset = 'top 60%';
            if (e.getAttribute('data-animation-delay') !== null) {
                delay = e.getAttribute('data-animation-delay');
            }

            if (e.getAttribute('data-duration') !== null) {
                duration = e.getAttribute('data-duration');
            }

            if (e.getAttribute('data-offset') !== null) {
                offset = e.getAttribute('data-offset');
            }
            gsap.from(e, {
                duration: duration,
                opacity: 0,
                y: 16,
                delay: delay,
                scrollTrigger: {
                    trigger: e,
                    start: offset,
                },
            });
        });


    }, [screenTwoLoaded, screenThirdLoaded]);

    return (<>
        <LoadingScreen setIsLoaded={setIsLoaded}/>
        <div style={{visibility: isLoaded ? 'visible' : 'hidden'}}>
            {activeScreen === 1 && <BetaBar name={'Footprint Tool'}/>}
            <Header appName={appName}/>
            <Banner isLoaded={isLoaded}
                    setScreenTwoLoaded={setScreenTwoLoaded}
                    setScreenThirdLoaded={setScreenThirdLoaded}
                    screenThirdLoaded={screenThirdLoaded}
                    portfolioValue={portfolioValue}
            />
            <FootprintFooter/>
            <SwiperGroup/>
        </div>
    </>);
};

export default Footprint;