import React, {useState, useContext} from 'react';
import {gsap} from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Settings} from '../../../store';
import Modal from 'react-bootstrap/Modal';
import 'swiper/css';
import 'swiper/css/effect-creative';
import {EffectCreative} from 'swiper';
import {
    filterAndListSectors,
    filterAndListIndustryGroup,
    filterAndListIndustries,
    filterAndListSubIndustries,
} from '../../../hooks/helperFunctions';
import translations from '../../../data/footprint/lang.json';
import BusinessSearchBlockv2 from '../BusinessSearchBlockv2';
import BFData from '../../../data/footprint/businessFootprint.json';

const SwiperGroup = (props) => {
    gsap.registerPlugin(ScrollTrigger);
    const {
        isOpen, hideModal, showCompanyRevenueModal,
    } = props;
    const [swiper, setSwiper] = useState(null);
    const settingsState = useContext(Settings.State);
    const dispatch = useContext(Settings.Dispatch);
    const {
        language,
        businessSector,
        businessIndustryGroup,
        businessIndustry,
        searchQuery,
    } = settingsState;
    const handleSector = (item) => {
        dispatch({
            type: 'setBusinessSector', payload: item,
        });
        swiper.slideTo(1);
    };
    const handleIndustryGroup = (item) => {
        dispatch({
            type: 'setBusinessIndustryGroup', payload: item,
        });
        swiper.slideTo(2);
    };
    const handleIndustry = (item) => {

        dispatch({
            type: 'setBusinessIndustry', payload: item,
        });
        swiper.slideTo(3);
    };
    const handleSubIndustries = (item) => {

        dispatch({
            type: 'setBusinessSubIndustry',
            payload: item.name,
        });
        dispatch({
            type: 'setBusinessSubIndustryValue',
            payload: item.value,
        });
        hideModal();
        showCompanyRevenueModal();
    };

    const updateIndustry = (value) => {
        BFData[language].searchableValues.map((item) => {
            if (item.sub_industry === value) {
                dispatch({
                    type: 'setBusinessSubIndustryValue',
                    payload: item.value,
                });
                dispatch({
                    type: 'setBusinessSubIndustry',
                    payload: item.sub_industry,
                });
                dispatch({
                    type: 'setBusinessSector',
                    payload: item.sector,
                });


                hideModal();
                showCompanyRevenueModal();
                dispatch({
                    type: 'setSearchQuery',
                    payload: '',
                });

            }
        });
    };
    const handleSearchedValue = (e) => {
        updateIndustry(e.target.innerText);
    };

    return (<Modal size="sm"
                   aria-labelledby="contained-modal-title-vcenter"
                   show={isOpen} onHide={hideModal}
                   className="business-dropdown"
                   id="business-dropdown-modal"

    >
        <h3 className="text-white text-center mb-5">{translations[language].screen2.chooseIndustry2}</h3>
        <BusinessSearchBlockv2 hideModal={hideModal} showCompanyRevenueModal={showCompanyRevenueModal}/>
        <div className="modal-body p-0">
            {/* <div className="p-4 position-absolute top-0 start-0 end-0 zIndex-3">
                <SearchBox/>
            </div> */}
            <div className="px-3">
                {searchQuery !== '' && searchQuery.map((option) => (
                        <div onClick={(e) => {
                            handleSearchedValue(e);
                        }} className="business-dropdown--item mb-2"
                             style={{cursor: 'pointer'}}
                             key={option.sub_industry}>{option.sub_industry}</div>))}
            </div>
            <Swiper
                    onSwiper={(swiper) => setSwiper(swiper)}
                    className={searchQuery === '' ? 'd-block m-0 h-100' : 'd-none m-0 h-100'}
                    cssMode={false}
                    slidesPerView={1}
                    effect={'creative'}
                    creativeEffect={{
                        prev: {
                            shadow: false,
                            translate: ['-20%', 0, -1],
                        },
                        next: {
                            translate: ['100%', 0, 0],
                        },
                    }}
                    loop={false}
                    autoHeight={false}
                    allowTouchMove={false}
                    mousewheel={false}
                    keyboard={false}
                    centeredSlides={true}
                    modules={[EffectCreative]}
            >
                <SwiperSlide className="" key={1}>

                    {filterAndListSectors(language).map((item) => {
                        return item !== undefined &&
                                <div className="business-dropdown--item mb-2"
                                     onClick={() => handleSector(item)} key={item}>{item}</div>;
                    })}
                </SwiperSlide>
                <SwiperSlide className="" key={2}>
                    <div className="business-dropdown--item mb-2 business-dropdown--back"
                         onClick={() => swiper.slideTo(0)}>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <mask id="mask0_1255_14757" style={{maskType: 'alpha'}} maskUnits="userSpaceOnUse" x="0"
                                  y="0" width="20" height="20">
                                <rect width="20" height="20" fill="#D9D9D9"/>
                            </mask>
                            <g mask="url(#mask0_1255_14757)">
                                <path d="M7.08334 15.0881L2.57213 10.5769C2.49462 10.5007 2.43277 10.4112 2.38659 10.3084C2.34041 10.2056 2.31732 10.1034 2.31732 10.0018C2.31732 9.90016 2.34029 9.79914 2.38622 9.69873C2.43216 9.5983 2.49413 9.50909 2.57213 9.4311L7.10418 4.91187C7.23211 4.7826 7.38508 4.71796 7.56309 4.71796C7.74111 4.71796 7.89475 4.78213 8.02403 4.91048C8.15329 5.03534 8.21659 5.18778 8.21393 5.36779C8.21125 5.5478 8.14795 5.69978 8.02403 5.82371L4.53522 9.33335H17.1778C17.3549 9.33335 17.5103 9.40021 17.644 9.53394C17.7777 9.66767 17.8445 9.82312 17.8445 10.0003C17.8445 10.1774 17.7777 10.3328 17.644 10.4663C17.5103 10.5999 17.3549 10.6666 17.1778 10.6666H4.53522L8.02403 14.1683C8.15864 14.3029 8.22461 14.4589 8.22193 14.6362C8.21926 14.8136 8.15411 14.9647 8.02647 15.0895C7.89557 15.2179 7.73785 15.282 7.55332 15.282C7.36879 15.282 7.21213 15.2174 7.08334 15.0881Z"
                                      fill="#1C1B1F"/>
                            </g>
                        </svg>
                    </div>
                    {filterAndListIndustryGroup(language, businessSector).map((item) => {
                        return item !== undefined &&
                                <div className="business-dropdown--item mb-2"
                                     onClick={() => handleIndustryGroup(item)}
                                     key={item}>{item}</div>;
                    })}
                </SwiperSlide>
                <SwiperSlide className="" key={3}>
                    <div className="business-dropdown--item mb-2 business-dropdown--back"
                         onClick={() => swiper.slideTo(1)}>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <mask id="mask0_1255_14757" style={{maskType: 'alpha'}} maskUnits="userSpaceOnUse" x="0"
                                  y="0" width="20" height="20">
                                <rect width="20" height="20" fill="#D9D9D9"/>
                            </mask>
                            <g mask="url(#mask0_1255_14757)">
                                <path d="M7.08334 15.0881L2.57213 10.5769C2.49462 10.5007 2.43277 10.4112 2.38659 10.3084C2.34041 10.2056 2.31732 10.1034 2.31732 10.0018C2.31732 9.90016 2.34029 9.79914 2.38622 9.69873C2.43216 9.5983 2.49413 9.50909 2.57213 9.4311L7.10418 4.91187C7.23211 4.7826 7.38508 4.71796 7.56309 4.71796C7.74111 4.71796 7.89475 4.78213 8.02403 4.91048C8.15329 5.03534 8.21659 5.18778 8.21393 5.36779C8.21125 5.5478 8.14795 5.69978 8.02403 5.82371L4.53522 9.33335H17.1778C17.3549 9.33335 17.5103 9.40021 17.644 9.53394C17.7777 9.66767 17.8445 9.82312 17.8445 10.0003C17.8445 10.1774 17.7777 10.3328 17.644 10.4663C17.5103 10.5999 17.3549 10.6666 17.1778 10.6666H4.53522L8.02403 14.1683C8.15864 14.3029 8.22461 14.4589 8.22193 14.6362C8.21926 14.8136 8.15411 14.9647 8.02647 15.0895C7.89557 15.2179 7.73785 15.282 7.55332 15.282C7.36879 15.282 7.21213 15.2174 7.08334 15.0881Z"
                                      fill="#1C1B1F"/>
                            </g>
                        </svg>
                    </div>
                    {filterAndListIndustries(language, businessSector, businessIndustryGroup).map((item) => {
                        return item !== undefined &&
                                <div className="business-dropdown--item mb-2"
                                     onClick={() => handleIndustry(item)} key={item}>{item}</div>;
                    })}
                </SwiperSlide>
                <SwiperSlide className="" key={4}>
                    <div className="business-dropdown--item mb-2 business-dropdown--back"
                         onClick={() => swiper.slideTo(2)}>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <mask id="mask0_1255_14757" style={{maskType: 'alpha'}} maskUnits="userSpaceOnUse" x="0"
                                  y="0" width="20" height="20">
                                <rect width="20" height="20" fill="#D9D9D9"/>
                            </mask>
                            <g mask="url(#mask0_1255_14757)">
                                <path d="M7.08334 15.0881L2.57213 10.5769C2.49462 10.5007 2.43277 10.4112 2.38659 10.3084C2.34041 10.2056 2.31732 10.1034 2.31732 10.0018C2.31732 9.90016 2.34029 9.79914 2.38622 9.69873C2.43216 9.5983 2.49413 9.50909 2.57213 9.4311L7.10418 4.91187C7.23211 4.7826 7.38508 4.71796 7.56309 4.71796C7.74111 4.71796 7.89475 4.78213 8.02403 4.91048C8.15329 5.03534 8.21659 5.18778 8.21393 5.36779C8.21125 5.5478 8.14795 5.69978 8.02403 5.82371L4.53522 9.33335H17.1778C17.3549 9.33335 17.5103 9.40021 17.644 9.53394C17.7777 9.66767 17.8445 9.82312 17.8445 10.0003C17.8445 10.1774 17.7777 10.3328 17.644 10.4663C17.5103 10.5999 17.3549 10.6666 17.1778 10.6666H4.53522L8.02403 14.1683C8.15864 14.3029 8.22461 14.4589 8.22193 14.6362C8.21926 14.8136 8.15411 14.9647 8.02647 15.0895C7.89557 15.2179 7.73785 15.282 7.55332 15.282C7.36879 15.282 7.21213 15.2174 7.08334 15.0881Z"
                                      fill="#1C1B1F"/>
                            </g>
                        </svg>
                    </div>

                    {filterAndListSubIndustries(language, businessSector, businessIndustryGroup, businessIndustry).map((item) => {
                        return item !== undefined &&
                                <div className="business-dropdown--item mb-2"
                                     onClick={() => handleSubIndustries(item)} key={item.name}>{item.name}</div>;

                    })}
                </SwiperSlide>
            </Swiper>
        </div>
    </Modal>);
};
export default SwiperGroup;