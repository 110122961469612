import React from 'react';


const CheckBox = (props) => {
    return (<>
        <div className="position-absolute translate-middle start-50 top-50" style={{}}>
            <input className={props.active ? 'checkbox checked' : 'checkbox'} id="cbx" type="checkbox"
                   style={{display: 'none'}}/>
            <div className="position-relative">
                <svg className="position-absolute" width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.4875 8.1375L12.8375 0.7875C13.1125 0.5125 13.4625 0.375 13.8875 0.375C14.3125 0.375 14.6625 0.5125 14.9375 0.7875C15.2125 1.0625 15.35 1.4125 15.35 1.8375C15.35 2.2625 15.2125 2.6125 14.9375 2.8875L6.5375 11.2875C6.2375 11.5875 5.8875 11.7375 5.4875 11.7375C5.0875 11.7375 4.7375 11.5875 4.4375 11.2875L0.5375 7.3875C0.2625 7.1125 0.125 6.7625 0.125 6.3375C0.125 5.9125 0.2625 5.5625 0.5375 5.2875C0.8125 5.0125 1.1625 4.875 1.5875 4.875C2.0125 4.875 2.3625 5.0125 2.6375 5.2875L5.4875 8.1375Z"
                          fill={props.active ? '#C7E3FF' : ''}/>
                </svg>
            </div>
        </div>
        {/*<svg width="36" height="36" viewBox="0 0 36 36" fill="none"*/}
        {/*     xmlns="http://www.w3.org/2000/svg">*/}
        {/*    <rect className="checked-task" width="35.8889" height="35.8889"*/}
        {/*          rx="17.9444" fill="#182361"/>*/}
        {/*    <mask id="mask0_843_1920" style={{maskType: 'alpha'}}*/}
        {/*          maskUnits="userSpaceOnUse" x="0" y="0" width="36" height="36">*/}
        {/*        <rect width="36" height="36" fill="#D9D9D9"/>*/}
        {/*    </mask>*/}
        {/*    <g className="checked-task" mask="url(#mask0_843_1920)">*/}
        {/*        <path d="M15.4397 20.0755L22.767 12.7481C23.0412 12.474 23.3901 12.3369 23.8138 12.3369C24.2375 12.3369 24.5864 12.474 24.8605 12.7481C25.1347 13.0223 25.2718 13.3712 25.2718 13.7949C25.2718 14.2186 25.1347 14.5675 24.8605 14.8417L16.4865 23.2157C16.1874 23.5148 15.8385 23.6643 15.4397 23.6643C15.0409 23.6643 14.692 23.5148 14.3929 23.2157L10.505 19.3278C10.2308 19.0536 10.0938 18.7047 10.0938 18.281C10.0938 17.8573 10.2308 17.5084 10.505 17.2343C10.7791 16.9601 11.128 16.823 11.5517 16.823C11.9754 16.823 12.3243 16.9601 12.5985 17.2343L15.4397 20.0755Z"*/}
        {/*              fill="#C7E3FF"/>*/}
        {/*    </g>*/}
        {/*    <rect opacity="0.3" x="1" y="1" width="34" height="34" rx="17"*/}
        {/*          stroke="#182361" stroke-width="2"/>*/}
        {/*</svg>*/}


    </>);


};

export default CheckBox;