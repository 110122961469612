import React from 'react';
import Impact from './Impact';
import Footprint from './Footprint';

const Home = (props) => {
    if (props.website === 'footprint' || props.website === 'footprint2') {
        return <Footprint appName={'Footprint'}/>;
    } else {
        return <Impact appName={'Impact'}/>;
    }
};

export default Home;