import React, {useEffect, useRef, useState} from 'react';
import {gsap} from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import translations from '../../../data/impact/lang.json';
import sdgData from '../../../data/impact/sdg-data.json';
import Modal from 'react-bootstrap/Modal';
import {Settings} from '../../../store';
import SDG from './SDG';
import {SpreadGrid} from '../../../hooks/spreadGrid';
import {MasonryGrid, JustifiedGrid, FrameGrid, PackingGrid} from '@egjs/react-grid';
import {getNegativeImpactValue} from '../../../hooks/helperFunctions';
import SDGModal from './SDGModal';
import SDGDetailsModal from './SDGDetailsModal';
import SDGRemainingModal from './SDGRemainingModal';

const SDGs = (props) => {
    gsap.registerPlugin(ScrollTrigger);
    const {investmentValue, negativeCalculation} = props;
    const settingsState = React.useContext(Settings.State);
    const {language} = settingsState;
    const [activeSDG, setActiveSDG] = useState(sdgData['SDG3']);
    const [activeSDGSector, setActiveSDGSector] = useState(0);
    const [totalNegativeImpact, setTotalNegativeImpact] = useState(0);
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenRemaining, setIsOpenRemaining] = useState(false);


    const [isOpenDetails, setIsOpenDetails] = useState(false);
    const [activeSDGCard, setActiveSDGCard] = useState();
    const [totalSDGImpactValue, setTotalSDGImpactValue] = useState(0);
    const el = useRef(null);
    const elGroup = gsap.utils.selector(el);
    const showModal = () => {
        if (!isOpen) {
            setIsOpen(true);
        }
    };

    const showRemainingModal = () => {
        if (!isOpenRemaining) {
            setIsOpenRemaining(true);
        }
    };

    const hideModalRemaining = () => {
        setIsOpenRemaining(false);
    };

    const hideModal = () => {
        document.getElementById('sdg_cards').querySelector('.card[data-sdg="' + activeSDGCard + '"]').click();
        setIsOpen(false);
    };
    const showModalDetails = () => {
        setIsOpenDetails(true);
    };
    const hideModalDetails = () => {
        setIsOpenDetails(false);
    };
    const staticValue = {
        largestShare: '<span class="word"><span>Climate</span></span> <span class="word"><span>action</span></span>',
    };


    useEffect(() => {
        let sum = 0;
        let delay = 0.05;
        gsap.set(elGroup('.grid__item'), {
            scale: 0,
            rotation: 0,
            x: 0,
            y: 0,
            opacity: 0,
        });
        let tl = gsap.timeline({
            scrollTrigger: {
                trigger: el.current,
                start: 'top 50%',
                end: 'bottom',
                scrub: false,
            },
        });
        Object.keys(sdgData).forEach((item, index) => {
            sum += sdgData[item].totalSectorImpact;

            tl.to(elGroup('.grid__item')[index], {
                opacity: 1,
                scale: 1,
                delay: delay,
                duration: 0.5,
            }, 'same');

            delay += 0.05;
        });
        setTotalSDGImpactValue(sum);


        [...elGroup('.grid')].forEach(grid => new SpreadGrid(grid));
        gsap.set(elGroup('.word span'), {
            y: 100,
            position: 'relative',
            opacity: 0,
            transitionTimingFunction: 'cubic-bezier(0.19, 1, 0.22, 1)',
            display: 'inline-block',
        });

        gsap.utils.toArray(elGroup('.word span')).forEach(function(e, index) {
            gsap.to(elGroup('.word span')[index], {
                scrollTrigger: {
                    trigger: el.current,
                    start: 'top 70%',
                    scrub: false,
                },
                y: 0,
                ease: 'expo.out',
                duration: 0.8,
                delay,
                opacity: 1,
            });
            delay += 0.05;
        });
    }, []);

    return (<section ref={el} id={'sdg_cards'}
                     className="block block--cards"
                     data-spy={'#sdg_cards'}
                     data-section-theme="blue">
        <div className="block__content">
            {/*<div className="position-absolute background-number opacity-25">{translations[language].general.currency} {negativeCalculation.toLocaleString(langSeparator(language), {maximumFractionDigits: 0})}</div>*/}

            <div className="container d-flex flex-column gap-5 gap-lg-6">
                <div className="row justify-content-center text-center mt-lg-5">
                    <div className="col-auto block__heading">
                        <h2 className="formue-animation large">SDGs</h2>
                        <h4 className="mb-5">UN Sustainable Development Goals</h4>
                        <p className="lead formue-animation" data-delay={0.1}>The negative impact is mapped to the goals, and these are the ones mostly affected:</p>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-lg-9">
                        <div className="formue-animation text-center mb-5" data-delay={0.4}>
                        <div className="dynamic-tooltip">
                          <div className="dynamic-tooltip__inner">
                            <p>Click them for more details</p>
                          </div>
                        </div>
                        </div>
                        <div className={'grid grid--sdg d-lg-grid flex-row'}
                             style={{
                                 gridTemplateColumns: 'repeat(14, 1fr)',
                                 gridTemplateRows: 'repeat(13, 1fr)',
                                 gap: 24,
                             }}

                             data-duration="1"
                             data-ease="elastic.out(0.5)"
                             data-scale="1.4"
                             data-max-rotation="20"
                             data-spread="150"
                             data-max-distance="700"
                        >
                            {Object.keys(sdgData).map((item) => {
                                let SDGData = sdgData[item];
                                return <SDG order={SDGData.order}
                                            negativeCalculation={negativeCalculation}
                                            setActiveSDGCard={setActiveSDGCard}
                                            grid={SDGData.grid}
                                            showModal={showModal}
                                            key={SDGData.id}
                                            item={item}
                                            setActiveSDG={setActiveSDG}
                                            activeSDG={activeSDG}
                                            totalSDGImpactValue={totalSDGImpactValue}/>;
                            })}
                        </div>
                        <p className="text-center small mt-5 formue-animation" data-delay={0.2}>
                            <a className="" role="button" onClick={showRemainingModal}><strong>More about UN Sustainable
                                Development Goals</strong></a></p>
                    </div>
                </div>
            </div>
        </div>
        <SDGModal
                setActiveSDGSector={setActiveSDGSector}
                showModalDetails={showModalDetails}
                setActiveSDG={setActiveSDG}
                isOpen={isOpen}
                activeSDGSector={activeSDGSector}
                hideModal={hideModal}
                activeSDG={activeSDG}
                totalSDGImpactValue={totalSDGImpactValue}
                negativeCalculation={negativeCalculation}
        />
        <SDGDetailsModal activeSDGSector={activeSDGSector}
                         activeSDG={activeSDG}
                         totalSDGImpactValue={totalSDGImpactValue}
                         isOpenDetails={isOpenDetails}
                         negativeCalculation={negativeCalculation}
                         hideModalDetails={hideModalDetails}/>
        <SDGRemainingModal isOpenRemaining={isOpenRemaining}
                           hideModalRemaining={hideModalRemaining}/>
    </section>);
};

export default SDGs;
