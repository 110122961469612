
import React from "react";
import sdgData from '../../../data/impact/sdg-data.json';
import translations from '../../../data/impact/lang.json';
import {Settings} from '../../../store';
import {langSeparator} from '../../../hooks/helperFunctions';


const SDGContinentItem = (props) => {
    const {item, activeSDG,showModalDetails, setActiveSDGSector,grid,totalSDGImpactValue, negativeCalculation} = props;
    const totalImpactIN2020 = item.totalNegativeImpact;
    const totalImpactIn2020Procent = (totalImpactIN2020 * 100) / activeSDG.totalSectorImpact;
    const settingsState = React.useContext(Settings.State)
    const {language} = settingsState;
    const percent = ((sdgData['SDG' + activeSDG.id].totalSectorImpact * 100) / totalSDGImpactValue);
    const activeSDGImpact = (negativeCalculation * percent) / 100;
    const finalCalculation = (activeSDGImpact * totalImpactIn2020Procent) / 100;

    return (<div  style={{gridArea: grid}} onClick={(e) => {
        document.body.classList.toggle('selected')
        e.currentTarget.classList.add('active')
        showModalDetails()
        setActiveSDGSector(item);

    }} className={"sectors__item"}
                 data-grid-column={item.columns}>
        <div className="content">
            <div className="overlay-fill" style={{width: totalImpactIn2020Procent + '%'}}></div>
            <div className="base-info">
              <span className="label">
                {item.name}
              </span>
                <h5 className="d-none d-lg-block text-right"> - {finalCalculation.toLocaleString(langSeparator(language), {maximumFractionDigits: 0})} {translations[language].general.currency}
                </h5>
            </div>
        </div>
    </div>)
}

export default SDGContinentItem;
