import React, {useState} from 'react';

import DeleteModal from './modals/DeleteModal';
import BaseModal from '../general/BaseModal';

const CartTask = (props) => {
    const {id, content, icon, type, popup, showContactModal} = props;
    const [isOpen, setIsOpen] = useState(false);
    const [element, setElement] = useState(null);
    const [isTaskModalOpen, setIsTaskModalOpen] = useState(false);

    const showTaskModal = () => {
        setIsTaskModalOpen(true);
    };
    const hideTaskModal = () => {
        setIsTaskModalOpen(false);
    };

    const handleTaskModal = (e) => {
        if (e.target.classList[0] === 'btn-task') {
            showTaskModal();
        }
    };
    const handleTaskModal2 = (e) => {
        showTaskModal();
    };

    const showModal = () => {
        setIsOpen(true);
    };
    const hideModal = () => {
        setIsOpen(false);
    };

    return (<div key={id}
                 className="btn-task d-flex align-items-center justify-content-between p-3 w-100 mb-2"
                 onClick={(e) => {
                     handleTaskModal(e);
                 }}>
        <div className="d-flex align-items-center gap-2" onClick={handleTaskModal2}>
            <img src={'./media/icons/' + icon} width="20" height="20" alt="icon"/>
            <p className="m-0 font-weight-medium" dangerouslySetInnerHTML={{__html: content}}></p>
        </div>
        <div onClick={(e) => {

            if (type === 'investments') {
                return;
            }
            showModal();
            setElement(e);
            //removeCartItem(e, activeTasks, dispatch);

        }
        } data-id={id}
             className={type === 'investments' ? 'd-none' : 'btn-close btn-sm d-flex align-items-center justify-content-center'}>
        </div>
        <DeleteModal type={type} element={element} isOpen={isOpen} hideModal={hideModal}/>
        <BaseModal showContactModal={showContactModal} content={popup} icon={'./media/icons/' + icon} title={content}
                   isBaseOpen={isTaskModalOpen} hideBaseModal={hideTaskModal}/>
    </div>);

};
export default CartTask;