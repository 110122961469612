import React, {useEffect, useRef, useState} from 'react';
import {langSeparator} from '../../../hooks/helperFunctions';
import TakeActionLine from './TakeActionLine';
import {gsap} from 'gsap';
import {Settings} from '../../../store';

const SDGColumn = (props) => {
    const {data, totalSDGImpactValue, negativeCalculation} = props;
    const [animateBar, setAnimateBar] = useState(false);
    const progressBarSection = useRef(null);
    const settingsState = React.useContext(Settings.State);
    const {language} = settingsState;
    useEffect(() => {
        setTimeout(() => {
            gsap.to(progressBarSection, {
                scrollTrigger: {
                    trigger: progressBarSection.current,
                    start: 'top 50%',
                    scrub: false,
                },
                onStart: () => {
                    setAnimateBar(true);
                },
            });
        }, 500);
    }, []);
    return (
            <div ref={progressBarSection} className="col-lg-4 formue-animation"  data-delay={0.2}>
                <div className="d-flex align-items-center mb-4 text-center justify-content-center justify-content-lg-start text-lg-start gap-3">
                    <img className="" src={'../media/Vector/loss.svg'} alt="SDGs"/>
                    <h5 className="">Impact by SDGs</h5>
                </div>
                <div className="d-flex flex-wrap flex-column">
                    {Object.keys(data).map((item, index) => {
                        const sdg = data[item];
                        const percent = ((sdg.totalSectorImpact * 100) / totalSDGImpactValue);
                        const value = ((negativeCalculation * percent) / 100).toLocaleString(langSeparator(language), {maximumFractionDigits: 0});
                        return <TakeActionLine animateBar={animateBar} order={sdg.order}
                                               name={sdg.name} percent={percent}
                                               value={value}/>;
                    })}
                </div>
            </div>
    );

};

export default SDGColumn;
