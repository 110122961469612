import React, {useRef} from 'react';
import SDGSectorItem from './SDGSectorItem';

const SDGSectorsTab = (props) => {
    const {
        activeSDG,
        showModalDetails,
        setActiveSDGSector,
        totalSDGImpactValue,
        negativeCalculation,
        setRemainingSectorsCalc
    } = props;
    const el = useRef(null);

    return (<>
                <div ref={el} className="grid grid--tabs d-block d-lg-grid"
                     style={{
                         gridTemplateColumns: 'repeat(5, 1fr)',
                         gridTemplateRows: 'repeat(4, 1fr)',
                         gap: 10,
                         height: 500,
                     }}>
                    {activeSDG.sectors.map((item, index) => <SDGSectorItem grid={item.grid}
                                                                           negativeCalculation={negativeCalculation}
                                                                           key={item.totalNegativeImpact}
                                                                           index={index}
                                                                           setActiveSDGSector={setActiveSDGSector}
                                                                           showModalDetails={showModalDetails}
                                                                           item={item}
                                                                           activeSDG={activeSDG}
                                                                           totalSDGImpactValue={totalSDGImpactValue}
                                                                           setRemainingSectorsCalc={setRemainingSectorsCalc}

                            />,
                    )}
                </div>
            </>
    );
};

export default SDGSectorsTab;