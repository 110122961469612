import React from 'react';

// Context
const State = React.createContext();
const Dispatch = React.createContext();

//App reducer
const reducer = (state, action) => {
    switch (action.type) {
        case 'setLanguage':
            return {
                ...state, language: action.payload,
            };
        case 'setPortfolioValue':
            return {
                ...state, portfolioValue: action.payload,
            };
        case 'setPortfolioAllocation':
            return {
                ...state, portfolioAllocation: action.payload,
            };
        case 'setIncomeLevel':
            return {
                ...state, incomeLevel: action.payload,
            };
        case 'setBusinessOwner':
            return {
                ...state, businessOwner: action.payload,
            };
        case 'setBusinessSector':
            return {
                ...state, businessSector: action.payload,
            };
        case 'setBusinessIndustryGroup':
            return {
                ...state, businessIndustryGroup: action.payload,
            };
        case 'setBusinessIndustry':
            return {
                ...state, businessIndustry: action.payload,
            };
        case 'setBusinessSubIndustry':
            return {
                ...state, businessSubIndustry: action.payload,
            };
        case 'setBusinessSubIndustryValue':
            return {
                ...state, businessSubIndustryValue: action.payload,
            };
        case 'setCompanyRevenue':
            return {
                ...state, companyRevenue: action.payload,
            };
        case 'setActiveScreen':
            return {
                ...state, activeScreen: action.payload,
            };
        case 'setLifeStyleValue':
            return {
                ...state, lifeStyleValue: action.payload,
            };
        case 'setInvestmentsValue':
            return {
                ...state, investmentsValue: action.payload,
            };
        case 'setBusinessValue':
            return {
                ...state, businessValue: action.payload,
            };
        case 'setTotalImpact':
            return {
                ...state, totalImpact: action.payload,
            };
        case 'setActiveTasks':
            return {
                ...state, activeTasks: action.payload,
            };
        case 'setSustainableShare':
            return {
                ...state, sustainableShare: action.payload,
            };
        case 'setEngagement':
            return {
                ...state, engagement: action.payload,
            };
        case 'setNegativeImpact':
            return {
                ...state, negativeImpact: action.payload,
            };
        case 'setScreenThirdLoaded':
            return {
                ...state, screenThirdLoaded: action.payload,
            };
        case 'setNewTotalFootprintValue':
            return {
                ...state, newTotalFootprintValue: action.payload,
            };
        case 'setLockCounterUp':
            return {
                ...state, lockCounterUp: action.payload,
            };
        case 'setFinalResult':
            return {
                ...state, finalResult: action.payload,
            };
        case 'setIsSwiperModalOpen':
            return {
                ...state, isSwiperModalOpen: action.payload,
            };
        case 'setSearchQuery':
            return {
                ...state, searchQuery: action.payload,
            };
        case 'setSwiperMove':
            return {
                ...state, swiperMove: action.payload,
            };
        case 'setActiveSlide':
            return {
                ...state, activeSlide: action.payload,
            };
        case 'setCheckboxItems':
            return {
                ...state, checkboxItems: action.payload,
            };
        case 'setActiveCurrencyLang':
            return {
                ...state, activeCurrencyLang: action.payload,
            };
        case 'setAlertTextNegativeImpact':
            return {
                ...state, alertTextNegativeImpact: action.payload,
            };
        case 'setCheckboxItemsSkip':
            return {
                ...state, checkboxItemsSkip: action.payload,
            };

        default:
            return state;
    }
};

// App provider
const Provider = ({children}) => {
    const defaultState = {
        language: 'no',
        activeCurrencyLang: 'no',
        portfolioValue: 0,
        portfolioAllocation: '',
        incomeLevel: '',
        businessOwner: '',
        searchQuery: '',
        companyRevenue: 0,
        activeScreen: 1,
        lifeStyleValue: 0,
        investmentsValue: 0,
        businessValue: 0,
        businessSector: '',
        businessIndustryGroup: '',
        businessIndustry: '',
        businessSubIndustry: '',
        businessSubIndustryValue: 0,
        activeTasks: [],
        sustainableShare: '',
        engagement: '',
        negativeImpact: '',
        screenThirdLoaded: false,
        lockCounterUp: false,
        totalImpact: 0,
        finalResult: false,
        alertTextNegativeImpact: '',
        isSwiperModalOpen: false,
        swiperMove: 0,
        activeSlide: 'lifestyle-block',
        checkboxItems: [],
        checkboxItemsSkip: false,
        sustainableColor: '#7B9B7E',
        neutralsColor: '#4C68A0',
        transitionColor: '#F1D5D9',
        significantColor: '#F2637B',
    };
    const [state, dispatch] = React.useReducer(reducer, {...defaultState});
    return (
            <State.Provider value={state}>
                <Dispatch.Provider value={dispatch}>{children}</Dispatch.Provider>
            </State.Provider>
    );
};

// Export
export const Settings = {
    State, Dispatch, Provider,
};
